import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #f1f1f1;

  .image-car {
    width: 200px;
  }

  .address-head{
    font-size:10px;
    font-weight: 700;
  }

  &.printable{
    padding-left:15px;
    width: 100%;
    max-width: none;
    display: flex;
    justify-content: start;
    align-items: flex-start;
    background:#fff;
    display:none;
    *{
      pageBreakInside: avoid;
    }
    img{
      width: 450px;
    }
    .infos-group{
      margin-bottom:30px;
      .title{
        font-size: 22px;
        font-weight: bold;
        border-bottom:1px solid;
        margin-bottom:5px;
        padding-bottom: 5px;
        color:#000;
        text-align:left;
      }
      .items{
        .item{
          display: flex;
          gap: 9px;
          font-size: 18px;
          span{
            font-weight: bold;
          }
        }
      }
      &.resume{
        margin-bottom: 25px;
        padding-bottom:25px;
        border-bottom:1px solid;
        .items{
          .item{
            display:block;
            pageBreakInside: avoid;
            ul{
              list-style:none;
            }
          }
        }
      }
    }
    @media print {
      display:flex;
    }
  }

  .wrapper-print {
    width: 80%;
    display: flex;
    justify-content: flex-end;
    margin: 0 auto;

    .print {
      display: flex;

      svg {
        margin-right: 6px;
      }
    }

    .btn-print {
      border: transparent;
      background: transparent;
      color: #236fbc;
    }
  }

  .content-to-print {
    display: none;
  }

  .description {
    color: #3c505a;
    font-size: 20px;
    text-align: center;
    margin: 0;
  }

  .title-description {
    font-weight: bold;
  }

  .title {
    color: #17536c;
    font-size: 25px;
    text-transform: uppercase;
    text-align: center;
    margin: 28px 0 0 0;
  }

  .buttons {
    width: 82%;
    margin: 30px auto;
    display: flex;
    justify-content: space-between;
    border-radius: 24px;
    flex-wrap: wrap;

    .btn-continue,
    .btn-back,
    .btn-save {
      width: 190px;
      height: 41px;
      font-size: 12px;
      text-transform: uppercase;
      margin: 8px;
    }

    .btn-back,
    .btn-save {
      background: transparent;
      border: 2px solid #226fbb;
      color: #226fbb;
    }

    .wrapper-button {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  @media (max-width: 760px) {
    .buttons {
      justify-content: center;
    }
  }

  @media print {
    header,
    .ffMYBn,
    button,
    .colors,
    .spn-menu,
    .options {
      display: none;
    }

    .content-to-print {
      display: block;

      p {
        margin-bottom: 8px;
      }
    }
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 82%;
  flex-wrap: wrap;
  max-width: 1400px;
  margin: 20px auto;
  align-items: center;
  font-family: 'Open Sans';
  padding-bottom: 16px;
  border: 2px solid #236fbc;
  border-radius: 16px;
  background: #ffffff;
  padding: 25px;
  color: #000000;

  h2 {
    text-align: left;
    width: -webkit-fill-available;
    font-size: 16px;
    font-weight: bold;
    color: #005faf;
    margin-bottom: 10px;
    margin-top: 10px;
  }
`

export const WrapperLine = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column !important;
  border-bottom: 1px solid grey;
  margin-bottom: 30px;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 10px;

    @media (min-width: 1024px) {
      margin-bottom: 20px;
    }

    & > p {
      width: auto;
      font-size: 14px;
      font-weight: 400;
      margin: 0;
      text-align: left;
    }
  }

  @media (min-width: 768px) {
    flex-direction: row !important;
  }
`

export const Summary = styled.div`
  width: 100%;

  p {
    font-size: 14px;
  }

  .longer-term,
  .monthly,
  .credit {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .value-offer {
      font-weight: 600;
    }
  }

  .credit {
    p {
      font-weight: bold;
    }
  }
`

export const Notes = styled.textarea`
  border: none;
  width: 100%;
`
