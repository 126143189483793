/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable array-callback-return */
import React, { useEffect, useState, useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Checkbox, Select } from "antd";
import { Modal, Input } from "antd";
import { AiTwotonePrinter } from "react-icons/ai";
import "moment/locale/pt-br";
import { useDispatch } from "react-redux";

import { Container, Content, DetailsCar, Summary, ContentModal } from "./styles";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import api from "../../services/api";
import formatValue from "../../utils/formatValue";
import Button from "../../components/Button";
import { useAuth } from "../../hooks/auth";
import { useToast } from "../../hooks/toast";
import { cnpjMask } from "../../utils/cnpjMask";
import * as Segment from "../../utils/segment";
import { setTabs } from "../../store/modules/tabsRegisterUser/actions";
import ContentPrintCheckout from "../../components/ContentPrintCheckout";
import ImgWithFallback from "../../components/ImgWithFallback";
import SelectOptions from "components/SelectOptions";
import { getAudiFilters } from "services/offers";
import { defaultBackendUrl } from "services/api";
import noImageCar from "assets/defaultImages/no-image.png";
import { SEGMENTS_ACTIVE } from "utils";
import ReactToPrint from "react-to-print";
import moment from "moment";
import Loading from "components/Loading";
import ProposalPDF from "components/ProposalPDF";
import { DownloadOutlined } from "@ant-design/icons";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

interface iPropsProducts {
    brandCode: number;
    color: string;
    productId: number;
    quantity: number;
    totalRequestCredit: number;
    modelCode: number;
    model: string;
    deadlines: {
        deadline: number;
    }[];
    kickback: {
        kickback: number;
    }[];
    monthlyKmValues: {
        monthlyKmValue: number;
    }[];
    productWithColor: {
        color: string;
        productId: number;
    }[];
    offerOptional: {
        optional: string;
    }[];
    monthlyInstallment: number;
    overrunKm: number;
    hullFranchiseValue: number;
    optional: string;
    deadline: any;
    deadlineInfo: string;
    monthlyKmValue: any;
    id: number;
    defaultValueKickback: any;
    defaultOptional: any;
    stockQuantity: number;
    amountAvailable: number;
    audiAccessories?: any;
    audiOptionals?: any;
    accessories: any[];
    optionals: any[];
}

interface iColors {
    color: string;
    productId: number;
}

const Checkout = () => {
    const componentRef = useRef<HTMLDivElement>(null);
    const hiddenContainerRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    const { Option } = Select;
    const history = useHistory();
    const { fullname, dealershipId, userId } = useAuth();
    const { addToast } = useToast();

    const [nameDealership, setNameDealership] = useState("");
    const [deadline, setDeadline] = useState(0);
    const [productsData, setProductsData] = useState<iPropsProducts[]>([]);
    const [customOptionalsProducts, setCustomOptionalsProducts] = useState<any[]>([] as any);
    const [overRunKm, setOverrunKm] = useState(0);
    const [searchClient, setSearchClient] = useState(false);
    const [totalMonthlyInstallment, setTotalMonthlyInstallment] = useState(0);
    const [CNPJ, setCNPJ] = useState("");
    const [requestCredit, setRequestCredit] = useState(0);
    const [longerTerm, setLongerTerm] = useState(0);

    const [dealershipAudi, setDealershipAudi] = useState(false);
    const [dealershipTrucks, setDealershipTrucks] = useState(false);
    const [dealershipVwPJ, setDealershipVwPJ] = useState(false);
    const [isEligibleCustomer, setIsEligibleCustomer] = useState(false);
    const [personalizedJourney, setPersonalizedJourney] = useState(false);
    const [segmentSummary, setSegmentSummary] = useState<any>(null);
    const [segment, setSegment] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const [showPDFComponent, setShowPDFComponent] = useState(false);

    

    const segmentId = Number(localStorage.getItem("@FleetSolutions:segmentId"));

  useEffect(() => {
    if (!userId) {
      history.push('/')
    }
  
    dispatch(setTabs({ id: '1' }))
  
    const productsLocal = localStorage.getItem('@FleetSolutions:cart')
  
    if (productsLocal) {
      const products = JSON.parse(productsLocal).map((product: iPropsProducts, index: number) => ({
        ...product,
        uniqueId: `${product.id}-${index}-${new Date().getTime()}` // Gerando um uniqueId para cada produto
      }));
  
      if (!products[0].quantity) {
        history.push(`/offers/pj/${segmentId}`)
      }
  
      setProductsData(products);
  
      const totalInstallment = products.map((item: iPropsProducts) => {
        return item.quantity * item.monthlyInstallment;
      });
  
      setTotalMonthlyInstallment(
        totalInstallment.reduce((acc: number, val: number) => acc + val, 0)
      );
    }
  }, []);
  

    useEffect(() => {
        const countRequestCredit = productsData.map((item: iPropsProducts) => {
            return item.totalRequestCredit;
        });

        const totalData = countRequestCredit.filter((e: any) => {
            if (e) {
                return e;
            }
        });

        setRequestCredit(totalData.reduce((acc: number, val: number) => acc + val, 0));
        if(segment=== null){
          loadSegments();
        }
    }, [productsData]);

    useEffect(() => {
        api.get(`/dealerships/${dealershipId}`).then((res) => {
            const { data } = res;

            const truckAPJ = Number(Segment.truckPJ) === segmentId || Number(Segment.truckAPJ) === segmentId;

            setNameDealership(data.name);

            setDealershipTrucks(truckAPJ);
            setDealershipVwPJ(Number(Segment.vwPJ) === segmentId);

            const allowPFAudi = Number(Segment.audiPF) === segmentId;
            const allowPJAudi = Number(Segment.audiPJ) === segmentId;

            if (allowPFAudi || allowPJAudi) {
                setDealershipAudi(true);
            }
        });
    }, [dealershipId]);

    const loadSegments = () => {
        api.get(SEGMENTS_ACTIVE)
            .then(({ data }) => {
                setSegment(data.filter((segment: { iD_Fleet: number }) => segment.iD_Fleet == segmentId)[0])
                setSegmentSummary(data.filter((segment: { iD_Fleet: number }) => segment.iD_Fleet == segmentId)[0]?.orderSummary);
                setPersonalizedJourney(data.filter((segment: { iD_Fleet: number }) => segment.iD_Fleet === segmentId)[0]?.personalizedJourney);
            })
            .catch(() => {
                addToast({
                    type: "error",
                    title: `Não foi possível listar os segmentos`,
                    description: "Por favor, entre em contato com o suporte.",
                });
            });
    };

    const fetchAudiExtrasOffers = (product: iPropsProducts, index?: number) => {
      const params = {
          modelCode: Number(product.modelCode),
          color: product.color,
          deadLine: product.deadline,
          monthlyKmValue: product.monthlyKmValue === "Selecione" ? 0 : product.monthlyKmValue,
          segmentId: segmentId,
          listAccessories: product.audiAccessories || [],
          listOptional: product.audiOptionals || [],
      };
  
      product.accessories = [];
      product.optionals = [];
  
      getAudiFilters(params).then(({ data }: { data: any }) => {
          const total = product.quantity * data.minPrice;
          product.totalRequestCredit = total * deadline;
          product.monthlyInstallment = data.minPrice;
          product.productId = data.productId;
          product.accessories = data.accessories;
          product.optionals = data.optionals;
          product.optional = data.products?.filter((productItem: any) => productItem.productId === product.productId)[0]?.optional;
  
          const totalInstallment = productsData.map((item: iPropsProducts) => {
              return item.quantity * item.monthlyInstallment;
          });
  
          setTotalMonthlyInstallment(totalInstallment.reduce((acc: number, val: number) => acc + val, 0));
  
          const newProductsData = productsData.map((productDataItem) => {
              if (productDataItem.id === product.id) {
                  return { ...productDataItem, ...product };
              }
              return productDataItem;
          });
  
          if (index !== undefined) {
              let updatedProducts = [...customOptionalsProducts]; 
  
              if (customOptionalsProducts.length === 0) {
                  updatedProducts.push(data.products);
              } else if (customOptionalsProducts.length === 1 && index !== 0) {
                  updatedProducts.push(data.products);
              } else if (customOptionalsProducts.length > 1) {
                  updatedProducts[index] = data.products;
              } else {
                  updatedProducts[index] = data.products;
              }
  
  
              setCustomOptionalsProducts(updatedProducts);
          }
  
  
          // Atualiza o estado com a nova lista de produtos
          setProductsData(newProductsData);
          setLoading(false);
      });
  };
  
    const handleAudiAccessories = (value: any[], item: iPropsProducts) => {
        const product = productsData.filter((e) => e.id === item.id)[0];
        product.audiAccessories = value;
       
        fetchAudiExtrasOffers(product);
    };

    const handleAudiOptionals = (value: any[], item: iPropsProducts) => {
        const product = productsData.filter((e) => e.id === item.id)[0];
        product.audiOptionals = value;
       
        fetchAudiExtrasOffers(product);
    };

    const handleCustomOptionals = async (value: any, itemIndex: number) => {
        const product = productsData[itemIndex];
        const total = product.quantity * value.monthlyInstallment;

        product.totalRequestCredit = total * deadline;
        product.monthlyInstallment = value.monthlyInstallment;
        product.productId = value.productId;
        product.amountAvailable = value.stockQuantity;
        product.quantity = product.amountAvailable === 0 ? 0 : 1;
        product.deadlineInfo = value?.deadlineInfo;
        product.overrunKm = value.overrunKm;

        setOverrunKm(value.overrunKm);
        const newProducts = [...productsData];
        newProducts[itemIndex] = product;

        setProductsData(newProducts);

        const totalInstallment = newProducts.map((item: iPropsProducts) => {
            return item.quantity * item.monthlyInstallment;
        });

        setTotalMonthlyInstallment(totalInstallment.reduce((acc: number, val: number) => acc + val, 0));
    };

  const handleFilterColor = useCallback(
    async (color: any, item: iPropsProducts, index: number) => {
      setLoading(true)
      await api
        .post(`/offers`, {
          modelCode: [item.modelCode],
          brandCode: [],
          colors: [color],
          page: true,
          quantityPerPage: 10,
          segmentId: segmentId
        })
        .then((response) => {
          item.deadlines = response.data.itens[0].deadlines;
          item.monthlyKmValues = response.data.itens[0].monthlyKmValues;
          item.kickback = response.data.itens[0].kickback;
          item.productId = response.data.itens[0].productId;
          item.color = color;
          item.deadline = 'Selecione';
          item.monthlyKmValue = 'Selecione';
          item.defaultValueKickback = 'Selecione';
          item.offerOptional = response.data.itens.map((dataItem: any) => ({
            optional: dataItem.optional,
          }));
          item.amountAvailable = response.data.itens[0].productWithColor[0].stockQuantity;
          item.quantity = item.amountAvailable === 0 ? 0 : item.quantity;
          item.deadlineInfo = response?.data?.itens[0]?.deadlineInfo;
  
          if (dealershipAudi) {
            item.defaultOptional = 'Selecione';
          }
          setLoading(false)
        });
  
      const updatedProducts = [...productsData];
      updatedProducts[index] = item; // Atualiza diretamente o produto no índice
  
      setProductsData(
        updatedProducts.sort((a: any, b: any) => {
          if (a.id > b.id) {
            return 1;
          }
          if (a.id < b.id) {
            return -1;
          }
          return 0;
        })
      );
    },
    [productsData]
  );
  

  const handleFilterContract = useCallback(
    async (deadline: any, item: iPropsProducts, index: number) => {
      setLoading(true)
      const updatedProduct = { ...item }; // Faz uma cópia do item atual
      updatedProduct.deadline = deadline; // Atualiza o prazo
      updatedProduct.monthlyKmValue = 'Selecione'; // Atualiza o prazo
      updatedProduct.defaultValueKickback = 'Selecione';
  
      if (personalizedJourney) {
        // updatedProduct.productId = 0;
      }

            // eslint-disable-next-line prefer-spread
            const maxTerm = Math.max.apply(
              Math,
              productsData.map(function (item: iPropsProducts) {
                return item.deadline
              })
            )
      
            if (isNaN(maxTerm) || deadline > maxTerm) {
              setLongerTerm(deadline)
            } else {
              setLongerTerm(maxTerm)
            }
  
      await api
        .get(
          `offers?modelCode=${updatedProduct.modelCode}&color=${updatedProduct.color}&deadline=${deadline}&segmentId=${segmentId}`
        )
        .then((response: any) => {
          if (response.data.length) {
            const { data } = response;
            const total = updatedProduct.quantity * data[0].monthlyInstallment;
  
            updatedProduct.totalRequestCredit = total * deadline;
            updatedProduct.monthlyInstallment = data[0].monthlyInstallment;
            updatedProduct.productId = data[0].productId;
            updatedProduct.offerOptional = data.map((dataItem: any) => ({
              optional: dataItem.optional,
            }));

            updatedProduct.monthlyKmValues = []

            const lista: string[] = []
  
            data.forEach((elemento: any) => {
              if (!lista.includes(elemento.monthlyKmValue)) {
                lista.push(elemento.monthlyKmValue)
              }
            })
  
            updatedProduct.monthlyKmValues = lista.map((dataItem: any) => ({
              monthlyKmValue: dataItem
            }))

            updatedProduct.amountAvailable = data[0].stockQuantity;
            updatedProduct.quantity = updatedProduct.amountAvailable === 0 ? 0 : item.quantity;
            updatedProduct.deadlineInfo = data[0]?.deadlineInfo;
          }
          setLoading(false)
        });
  
      // Atualiza o produto específico na lista
      setProductsData((prevProducts) => {
        const updatedProducts = prevProducts.map((p, i) => 
          i === index ? updatedProduct : p
        );
  
        const totalInstallment = updatedProducts.map((product: iPropsProducts) => {
          return product.quantity * product.monthlyInstallment;
        });
  
        setTotalMonthlyInstallment(
          totalInstallment.reduce((acc: number, val: number) => acc + val, 0)
        );
  
        return updatedProducts;
      });

      setDeadline(deadline)
    },
    [productsData, deadline, segmentId]
  );
  
  
  

  const handleFranchise = useCallback(
    async (kmValue, item: iPropsProducts, index: number) => {
      setLoading(true)
      const updatedProduct = { ...item }; 
      updatedProduct.monthlyKmValue = kmValue;
      updatedProduct.defaultValueKickback = 'Selecione';
  
      if (personalizedJourney) {
        // updatedProduct.productId = 0; // Se necessário, redefina o productId
      }
  
      await api
        .get(
          `offers?modelCode=${updatedProduct.modelCode}&monthlyKmValue=${kmValue}&color=${updatedProduct.color}&deadLine=${updatedProduct.deadline}&segmentId=${segmentId}`
        )
        .then((response: any) => {
          if (response.data.length) {
            const { data } = response;
            const total = updatedProduct.quantity * data[0].monthlyInstallment;
  
            updatedProduct.totalRequestCredit = total * updatedProduct.deadline;
            updatedProduct.monthlyInstallment = data[0].monthlyInstallment;
            updatedProduct.productId = data[0].productId;
            updatedProduct.offerOptional = data.map((dataItem: any) => ({
              optional: dataItem.optional,
            }));
            updatedProduct.amountAvailable = data[0].stockQuantity;
            updatedProduct.quantity = updatedProduct.amountAvailable === 0 ? 0 : item.quantity;
            updatedProduct.deadlineInfo = data[0]?.deadlineInfo;
            updatedProduct.overrunKm = data[0].overrunKm;
            updatedProduct.monthlyKmValue = data[0].monthlyKmValue;
  
            setOverrunKm(data[0].overrunKm);
            setLoading(false)
          }
        });
  
      // Atualiza o produto específico na lista
      setProductsData((prevProducts) => {
        const updatedProducts = prevProducts.map((p, i) => 
          i === index ? updatedProduct : p
        );
  
        // Recalcula o total de parcelas
        const totalInstallment = updatedProducts.map((product: iPropsProducts) => {
          return product.quantity * product.monthlyInstallment;
        });
  
        setTotalMonthlyInstallment(
          totalInstallment.reduce((acc: number, val: number) => acc + val, 0)
        );

              const shouldFectchAudiExtras =
        updatedProduct.modelCode &&
        updatedProduct.color &&
        updatedProduct.deadline &&
        updatedProduct.monthlyKmValue

      if (personalizedJourney && shouldFectchAudiExtras) {
        fetchAudiExtrasOffers(updatedProduct, index)
      }
  
        return updatedProducts;
      });
      
    },
    [deadline, productsData]
  );
  
  
  
  

  const handleOptional = useCallback(
    async (optional, item: iPropsProducts, index: number) => {
      setLoading(true)
      const updatedProduct = { ...item, defaultOptional: optional }; // Atualiza o opcional diretamente no item
  
      await api
        .get(
          `offers?modelCode=${updatedProduct.modelCode}&monthlyKmValue=${updatedProduct.monthlyKmValue}&color=${updatedProduct.color}&deadLine=${updatedProduct.deadline}&segmentId=${segmentId}`
        )
        .then((response: any) => {
          if (response.data.length) {
            const { data } = response;
            const optionalData = data.find((e: any) => e.optional === optional);
            const total = updatedProduct.quantity * optionalData.monthlyInstallment;
  
            updatedProduct.totalRequestCredit = total * updatedProduct.deadline;
            updatedProduct.monthlyInstallment = optionalData.monthlyInstallment;
            updatedProduct.productId = optionalData.productId;
            updatedProduct.amountAvailable = optionalData.stockQuantity;
            updatedProduct.quantity = updatedProduct.amountAvailable === 0 ? 0 : item.quantity;
            updatedProduct.deadlineInfo = optionalData?.deadlineInfo;
  
            // Atualiza diretamente o produto no índice
            setProductsData((prevProducts) => {
              const updatedProducts = [...prevProducts];
              updatedProducts[index] = updatedProduct;
  
              return updatedProducts;
            });
  
            const totalInstallment = productsData.map((product: iPropsProducts) => {
              return product.quantity * product.monthlyInstallment;
            });
  
            setTotalMonthlyInstallment(
              totalInstallment.reduce((acc: number, val: number) => acc + val, 0)
            );
            setLoading(false)
          }
        });
    },
    [productsData]
  );
  
  const handleContinueOrder = useCallback(() => {
    
    const dataValidations = []

        setSearchClient(true);

        const productsLocal = localStorage.getItem("@FleetSolutions:cart");

        if (productsLocal) {
            const products = JSON.parse(productsLocal);
            localStorage.setItem("@FleetSolutions:cartProposal", JSON.stringify(products));
        }

        localStorage.setItem("@FleetSolutions:cart", JSON.stringify(productsData));
    }, [productsData, addToast]);

    const handleIncrement = (value: iPropsProducts, index: number) => {
      const updatedProducts = [...productsData];
      const product = updatedProducts[index];
  
      if (product.quantity < value.amountAvailable) {
          product.quantity++;
      }
  
      if (deadline !== 0) {
          const total = product.quantity * product.monthlyInstallment;
          product.totalRequestCredit = total * deadline;
      }
  
      setProductsData(updatedProducts);
      updateTotalMonthlyInstallment(updatedProducts);
  };
  
  const handleDecrement = (value: iPropsProducts, index: number) => {
      const updatedProducts = [...productsData];
      const product = updatedProducts[index];
  
      if (product.quantity > 1) {
          product.quantity--;
      }
  
      if (deadline !== 0) {
          const total = product.quantity * product.monthlyInstallment;
          product.totalRequestCredit = total * deadline;
      }
  
      setProductsData(updatedProducts);
      updateTotalMonthlyInstallment(updatedProducts);
  };
  
  const updateTotalMonthlyInstallment = (products: iPropsProducts[]) => {
      const totalInstallment = products.map((item) => item.quantity * item.monthlyInstallment);
      setTotalMonthlyInstallment(totalInstallment.reduce((acc, val) => acc + val, 0));
  };

    const handleFindClient = () => {
        if (CNPJ.length < 18) {
            return addToast({
                title: "CNPJ inválido",
                type: "error",
                description: "Digite um CNPJ válido para continuar.",
            });
        }

        localStorage.setItem("@FleetSolutions:cnpjCompany", CNPJ);

        const clearCpfCnpj = CNPJ.replace(".", "").replace(".", "").replace("/", "").replace("-", "");

        setCNPJ(clearCpfCnpj);

        api.get(`/users/${clearCpfCnpj}/cpfCnpj`)
            .then(() => {
                api.get(`users/CheckUserRenew/${userId}`).then((res) => {
                    const { data } = res;

                    if (data) {
                        setIsEligibleCustomer(true);
                    } else {
                        history.push(`/list-dealership/register-company/${clearCpfCnpj}`);
                        dispatch(setTabs({ id: "1" }));
                    }
                });
            })
            .catch((error) => {
                const { status } = error.response;
                if (status === 404) {
                    history.push(`/list-dealership/register-company/${clearCpfCnpj}`);
                }
            });
    };

  const handleChangeCnpj = (e: any) => {
    setCNPJ(cnpjMask(e.target.value))
  }

  const handleChangeFilterByKickback = async (
    kickback: string,
    item: iPropsProducts,
    index: number
  ) => {
    setLoading(true)
    const updatedProduct = { ...item, defaultValueKickback: kickback }; // Faz uma cópia do item
  
    await api
      .get(
        `offers?modelCode=${updatedProduct.modelCode}&monthlyKmValue=${updatedProduct.monthlyKmValue}&color=${updatedProduct.color}&deadLine=${updatedProduct.deadline}&kickback=${kickback}&segmentId=${segmentId}`
      )
      .then((response: any) => {
        if (response.data.length) {
          const { data } = response;
          const total = updatedProduct.quantity * data[0].monthlyInstallment;
  
          updatedProduct.totalRequestCredit = total * updatedProduct.deadline;
          updatedProduct.monthlyInstallment = data[0].monthlyInstallment;
          updatedProduct.productId = data[0].productId;
          updatedProduct.offerOptional = data.map((dataItem: any) => ({
            optional: dataItem.optional,
          }));
          updatedProduct.amountAvailable = data[0].stockQuantity;
          updatedProduct.quantity = updatedProduct.amountAvailable === 0 ? 0 : item.quantity;
          updatedProduct.deadlineInfo = data[0]?.deadlineInfo;
        }

        setLoading(false)
      });
  
    // Atualiza o produto específico na lista
    setProductsData((prevProducts) => {
      const updatedProducts = prevProducts.map((p, i) => 
        i === index ? updatedProduct : p
      );
  
      const totalInstallment = updatedProducts.map((item: iPropsProducts) => {
        return item.quantity * item.monthlyInstallment;
      });
  
      setTotalMonthlyInstallment(
        totalInstallment.reduce((acc: number, val: number) => acc + val, 0)
      );
  
      return updatedProducts;
    });
  };
  
  
  

    const handleChangeEligible = () => {
        history.push(`/list-dealership/register-company/${CNPJ}`);
        dispatch(setTabs({ id: "1" }));
        setIsEligibleCustomer(true);
    };

    const hasProductWithoutQuantity = () => {
        const productWithoutQuantity = productsData.find((product) => product.quantity === 0);
        return Boolean(productWithoutQuantity);
    };

    const hasProductWithoutProductId = () => {
        const productWithoutProductId = productsData.find((product) => product.productId === 0);
        return Boolean(productWithoutProductId);
    };

    const hasEmptyField = () => {
        const emptyField = productsData.find((product) => {
            const requiredFields = [product.deadline, product.monthlyKmValue];

            if (dealershipVwPJ || segmentId === 14) {
                requiredFields.push(product.defaultValueKickback);
            } else if (!personalizedJourney) {
                //requiredFields.push(product.defaultOptional)
            }

            return requiredFields.includes(undefined) || requiredFields.includes("Selecione");
        });

        return Boolean(emptyField);
    };

    const proposalDate = () => {
        const currentDate = new Date();

        const day = currentDate.getDate().toString().padStart(2, "0");
        const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
        const year = currentDate.getFullYear().toString();

        const hours = currentDate.getHours().toString().padStart(2, "0");
        const minutes = currentDate.getMinutes().toString().padStart(2, "0");

        const formattedDateTime = `${day}/${month}/${year} - ${hours}:${minutes}`;

        return formattedDateTime;
    };


    const handleGeneratePdf = () => {
      setShowPDFComponent(true);
      setTimeout(() => {
        if (hiddenContainerRef.current) {
          html2canvas(hiddenContainerRef.current, {
              scale: 1,
              useCORS: true
          }).then((canvas) => {
              const contentWidth = canvas.width;
              const contentHeight = canvas.height;
  
              const pdfWidth = contentWidth; 
              const pdfHeight = contentHeight;
  
              const doc:any = new jsPDF({
                  orientation: 'portrait',
                  unit: 'pt',
                  format: [pdfWidth, pdfHeight],
              });
 
              doc.setPrecision(2);
              doc.setFont("Open Sans", "normal");
              doc.html(hiddenContainerRef.current as HTMLElement, {
                  x: 0,
                  y: 0,
                  html2canvas: {
                      scale: 1,
                      useCORS: true,
                  },
                  callback: function (doc:any) {
                      const totalPages = doc?.internal?.getNumberOfPages();
                      if (totalPages > 1) {
                          doc.deletePage(totalPages);
                      }
                      setTimeout(() => {
                        doc.save("Proposta_Comercial_Canal_Indireto.pdf");
                      }, 500);
                      setShowPDFComponent(false);

                  },
              });
          });
      }
      }, 1000);
    };

    return (
        <div style={{backgroundColor: '#fff', minHeight:'100vh'}}>
            <Container>
                <Header />
                {showPDFComponent && (
                  <div ref={hiddenContainerRef} style={{ position: "fixed", zIndex: "-9999999999", overflow: "hidden", top: 0 }}>
                    <ProposalPDF
                        parameters={{
                            productsList:productsData,
                            seller: fullname,
                            segmentSummary: segmentSummary,
                            proposalDate: proposalDate(),
                            segment: segment,
                            dealer:nameDealership
                        }}
                    />
                  </div>
                )}
                {productsData.length &&
                    productsData.map((item: iPropsProducts, idx: number) => {
                        return (
                            <Content key={idx}>
                                {loading && (
                                  <div className="loader">
                                    <Loading text={""} />
                                  </div>
                                )}
                                <ImgWithFallback className="image-car" src={`${defaultBackendUrl}/s3/veiculos/canal-indireto/${item.modelCode}.webp`} fallbacks={[`${defaultBackendUrl}/s3/veiculos/canal-indireto/${item.modelCode}.png`, `${noImageCar}`]} alt={item.model} />
                                <DetailsCar>
                                    <div className="info-car">
                                        <h1>{item.model}</h1>
                                        {!personalizedJourney && !dealershipAudi && !dealershipTrucks && <p>Opcionais: {item.optional}</p>}
                                        <div className="info-offer">
                                            <div className="colors">
                                                <p>Cores disponíveis</p>

                      <Select
                        defaultValue={item.color}
                        style={{ width: 200, border: '1px solid #0C5FB1' }}
                        onChange={(value) => handleFilterColor(value, item, idx)}
                      >
                        {item.productWithColor &&
                          item.productWithColor

                            .sort((a: any, b: any) => {
                              if (a.color < b.color) {
                                return -1
                              }
                              if (a.color > b.color) {
                                return 1
                              }
                              return 0
                            })
                            .map((subItem: iColors, idx: number) => (
                              <Option key={idx} value={subItem.color}>
                                {subItem.color}
                              </Option>
                            ))}
                      </Select>
                    </div>
                    <div className="options">
                      <p>Período</p>
                      <Select
                        style={{ width: 120, border: '1px solid #0C5FB1' }}
                        onChange={(value) => handleFilterContract(value, item, idx)}
                        value={item.deadline || 'Selecione'}
                      >
                        {item.deadlines &&
                          item.deadlines
                            .sort((a: any, b: any) => {
                              if (a.deadline < b.deadline) {
                                return -1
                              }
                              if (a.deadline > b.deadline) {
                                return 1
                              }
                              return 0
                            })
                            .map((subItem: any, idx: number) => (
                              <Option key={idx} value={subItem.deadline}>
                                {subItem.deadline} meses
                              </Option>
                            ))}
                      </Select>
                    </div>
                    <div className="options">
                      <p>Franquia Km/mês</p>
                      <Select
                        style={{ width: 120, border: '1px solid #0C5FB1' }}
                        onChange={(value) => handleFranchise(value, item, idx)}
                        value={item.monthlyKmValue || 'Selecione'}
                      >
                        {item.monthlyKmValues &&
                          item.monthlyKmValues
                            .sort((a: any, b: any) => {
                              if (a.monthlyKmValue < b.monthlyKmValue) {
                                return -1
                              }
                              if (a.monthlyKmValue > b.monthlyKmValue) {
                                return 1
                              }
                              return 0
                            })
                            .map((subItem: any, idx: number) => (
                              <Option key={idx} value={subItem.monthlyKmValue}>
                                {subItem.monthlyKmValue} Km
                              </Option>
                            ))}
                      </Select>
                    </div>

                    {/* {!dealershipAudi && !dealershipTrucks && ( */}
                    <div className="options none">
                      <p>Comissão</p>
                      <Select
                        style={{ width: 200, border: '1px solid #0C5FB1' }}
                        onChange={(value) =>
                          handleChangeFilterByKickback(value, item, idx)
                        }
                        value={item.defaultValueKickback || 'Selecione'}
                      >
                        {item.kickback &&
                          item.kickback
                            .sort((a: any, b: any) => {
                              if (a.kickback < b.kickback) {
                                return -1
                              }
                              if (a.kickback > b.kickback) {
                                return 1
                              }
                              return 0
                            })
                            .map((subItem: any, idx: number) => (
                              <Option key={idx} value={subItem.kickback}>
                                {subItem.kickback}
                              </Option>
                            ))}
                      </Select>
                    </div>
                    {/* )} */}

                                            <div className="button-quantity">
                                                <p>Quantidade</p>
                                                <div className="wrapper-quantity">
                                                    <Button onClick={() => handleDecrement(item, idx)}>-</Button>
                                                    <span>{item.quantity}</span>
                                                    <Button onClick={() => handleIncrement(item, idx)}>+</Button>
                                                </div>
                                                <div className="stock">{`${item.amountAvailable} ${item.amountAvailable > 1 ? "disponíveis" : "disponível"}`}</div>
                                            </div>
                                        </div>

                  {personalizedJourney && (
                  <>
                    {segmentId === 27 ? (
                      <>
                      {item.optionals?.length > 0 && item.monthlyKmValue !== 'Selecione'  && (
                                              <div className='custom-optionals'>
                                              {customOptionalsProducts[idx]?.filter((itemOptFilter:any) => {
                                                                                          // Verifica se há itens selecionados
                                                                                          const hasSelectedAccessory = item.accessories && item.accessories.length;
                      
                                                                                          // Aplica a filtragem com base na presença de itens selecionados
                                                                                          return hasSelectedAccessory
                                                                                              ? itemOptFilter.optional.toLowerCase().includes("blindagem")
                                                                                              : !itemOptFilter.optional.toLowerCase().includes("blindagem");
                                                                                      })
                                                                                      .map((itemMap:any, idxOpt:any) => {
                                                                                          const formattedText = "- " + itemMap.optionalText.replace(/ ; /g, " ;<br/>- ");
                      
                                                                                          return (
                                                                                              <div
                                                                                                  key={`item`+idxOpt}
                                                                                                  onClick={() => handleCustomOptionals(itemMap, idx)}
                                                                                                  className={
                                                                                                      item.productId === itemMap.productId
                                                                                                          ? "optional-group selected"
                                                                                                          : "optional-group"
                                                                                                  }
                                                                                              >
                                                                                                  <div className="head-group">
                                                                                                      <Checkbox
                                                                                                          checked={item.productId === itemMap.productId}
                                                                                                          onChange={() => handleCustomOptionals(itemMap, idx)}
                                                                                                      ></Checkbox>
                                                                                                      <h4>Pacote de opcionais {idxOpt + 1}</h4>
                                                                                                  </div>
                                                                                                  <span dangerouslySetInnerHTML={{ __html: formattedText }} />
                                                                                              </div>
                                                                                          );
                                                                                      })}
                                            </div>
                                            
                      )}
                                              {item.accessories?.length > 0 && (
                          <div className="options-offers-audi">
                            <p>Acessórios</p>
                            <SelectOptions
                              data={item.accessories}
                              onChange={(value: any[]) => handleAudiAccessories(value, item)}
                            />
                          </div>
                        )}
                      </>

                    ) : (
                      <>
                        {item.accessories?.length > 0 && (
                          <div className="options-offers-audi">
                            <p>Acessórios</p>
                            <SelectOptions
                              data={item.accessories}
                              onChange={(value: any[]) => handleAudiAccessories(value, item)}
                            />
                          </div>
                        )}
                        {item.optionals?.length > 0 && (
                          <div className="options-offers-audi">
                            <p>Opcionais</p>
                            <SelectOptions
                              data={item.optionals}
                              onChange={(value: any[]) => handleAudiOptionals(value, item)}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}


                  {((!personalizedJourney && dealershipAudi) || dealershipTrucks) && (
                    <div className="options-offers">
                      <p>Opcionais</p>
                      <Select
                        style={{
                          width: '71%',
                          minWidth: 100,
                          border: '1px solid #0C5FB1'
                        }}
                        onChange={(value) => handleOptional(value, item, idx)}
                        value={item.defaultOptional || 'Selecione'}
                      >
                        {item.offerOptional.map((item: any, idx: number) => (
                          <Option key={idx} value={item.optional}>
                            {item.optional}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  )}
                  <div className="info-deadline">
                    <p className="title">Prazo de entrega:</p>
                    <p className="info">{item.deadlineInfo}</p>
                  </div>
                </div>
              </DetailsCar>

                                <div className="info-important">
                                    <p className="title">Total</p>
                                    <span className="value-total">{formatValue(item.monthlyInstallment * item.quantity)}</span>
                                    <p className="title">Valor unitário</p>
                                    <span className="unitary-value">{formatValue(item.monthlyInstallment)}</span>
                                    <div className="info">
                                        <p>Custo/Km Excedente</p>
                                        <span>{formatValue(overRunKm)}</span>
                                    </div>
                                    <div className="info">
                                        <p>Coparticipação em sinistro:</p>
                                        <span>5% do preço público do veículo</span>
                                    </div>
                                </div>
                            </Content>
                        );
                    })}

                <Modal width="368px" title="" onCancel={() => setSearchClient(false)} footer={null} visible={searchClient}>
                    <ContentModal>
                        <h3>Pesquisar cliente</h3>
                        <p>Digite o CNPJ para identificar um cliente</p>
                        <Input className="input" id="search-client" type="text" name="search-client" placeholder="CNPJ" onChange={handleChangeCnpj} value={CNPJ} maxLength={18} />

                        <Button onClick={handleFindClient}>Pesquisar</Button>
                    </ContentModal>
                </Modal>

                <Modal width="368px" title="" onCancel={handleChangeEligible} footer={null} visible={isEligibleCustomer}>
                    <ContentModal>
                        <h3>Cliente elegível</h3>
                        <p>Cliente elegível para desconto de renovação, continue com o pedido, o valor final da oferta será apresentado na tela de resumo.</p>

                        <Button onClick={handleChangeEligible}>OK</Button>
                    </ContentModal>
                </Modal>

                <Summary>
                    <h3>Resumo</h3>
                    <p className="stock-availability">Veículos sujeitos a disponibilidade de estoque</p>
                    <div className="longer-term">
                        <p>Maior prazo</p>
                        <p>{longerTerm} meses</p>
                    </div>
                    <div className="monthly">
                        <p>Parcela mensal</p>
                        <p>{formatValue(totalMonthlyInstallment)}</p>
                    </div>
                    <div className="credit">
                        <p>Valor total de contrato</p>
                        <p>{formatValue(requestCredit)}</p>
                    </div>
                </Summary>

                <div className="buttons">
                    <Button className="btn-back" onClick={() => history.goBack()}>
                        VOLTAR
                    </Button>
                    <div className="wrapper-actions">
                        {/* <ReactToPrint
                            trigger={() => (
                                <button className="btn-print">
                                    <div className="print">
                                        <AiTwotonePrinter color="#236FBC" size={25} /> Imprimir
                                    </div>
                                </button>
                            )}
                            content={() => componentRef.current}
                        /> */}
                                <button onClick={() => handleGeneratePdf()} className="btn-print">
                                    <div className="print">
                                        <DownloadOutlined color="#236FBC" size={30} /> Exportar PDF da Proposta
                                    </div>
                                </button>
                        <Button className="btn-continue" onClick={handleContinueOrder} disabled={hasProductWithoutQuantity() || hasEmptyField() || hasProductWithoutProductId()}>
                            CONTINUAR
                        </Button>
                    </div>
                </div>
                <Footer />
            </Container>
            <Container className="printable" ref={componentRef}>
                {productsData.length &&
                    productsData.map((item: any, idx: number) => (
                        <div key={idx}>
                            <ImgWithFallback className="image-car" src={`${defaultBackendUrl}/s3/veiculos/canal-indireto/${item.modelCode}.webp`} fallbacks={[`${defaultBackendUrl}/s3/veiculos/canal-indireto/${item.modelCode}.png`, `${noImageCar}`]} alt={item.model} />

                            <div className="infos-group">
                                <div className="title">Informações do veículo:</div>
                                <div className="items">
                                    <div className="item">
                                        <span>Modelo:</span>
                                        {item.model}
                                    </div>
                                    <div className="item">
                                        <span>Cor:</span>
                                        {item.color}
                                    </div>
                                    <div className="item">
                                        <span>Ano:</span>
                                        {item.modelYear}
                                    </div>
                                    <div className="item">
                                        <span>Quantidade:</span>
                                        {item.quantity}
                                    </div>
                                </div>
                            </div>

                            <div className="infos-group">
                                <div className="title">Informações do contrato:</div>
                                <div className="items">
                                    <div className="item">
                                        <span>Período:</span>
                                        {item.deadline} meses
                                    </div>
                                    <div className="item">
                                        <span>Franquia mensal:</span>
                                        {item.monthlyKmValue} Kms
                                    </div>
                                    <div className="item">
                                        <span>Total da parcela:</span>
                                        {formatValue(item?.monthlyInstallment)}
                                    </div>
                                    <div className="item">
                                        <span>Total do contrato:</span>
                                        {formatValue(item?.totalRequestCredit)}
                                    </div>
                                    <div className="item">
                                        <span>Valor do KM excedente:</span>
                                        {formatValue(item?.overrunKm)}
                                    </div>
                                    <div className="item">
                                        <span>Coparticipação em sinistro:</span>
                                        5% do preço público do veículo
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}

                <div className="infos-group resume">
                    <div className="title">Resumo do pedido</div>
                    <div className="items">
                        <div className="item">
                            <div dangerouslySetInnerHTML={{ __html: segmentSummary }}></div>
                        </div>
                    </div>
                </div>

                <div className="infos-group footer-group">
                    <div className="items">
                        <div className="item">
                            <span>Nome da Concessionária:</span>
                            {nameDealership}
                        </div>
                        <div className="item">
                            <span>Nome do Vendedor:</span>
                            {fullname}
                        </div>
                        <div className="item">
                            <span>Data/hora da cotação:</span>
                            {moment().format("DD/MM/YYYY - hh:mm")}
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default Checkout;