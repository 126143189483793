/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable array-callback-return */
import React, { useEffect, useState, useCallback, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Tooltip, Modal, Input, Select, Checkbox } from "antd";
import { useDispatch } from "react-redux";
import noImageColor from "../../../assets/defaultImages/no-image-color.jpg";
import noImageCar from "../../../assets/defaultImages/no-image.png";
import { isEmpty } from "lodash";
import { cpfMask } from "../../../utils/cpfMask";
import { Container, Content, ImageCar, DetailsCar, TextCar, ButtonLine, Colors, ValuePlan, AlignLeft, ContentModal } from "./styles";

import iconInfo from "../../../assets/icons/info.svg";
import { defaultBackendUrl } from "../../../services/api";
import api from "../../../services/api";
import formatValue from "../../../utils/formatValue";
import { useAuth } from "../../../hooks/auth";
import SignIn from "../../SignIn";
import { loginApp } from "../../../store/modules/login/actions";
import { useToast } from "../../../hooks/toast";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import Button from "../../../components/Button";
import { setTabs } from "../../../store/modules/tabsRegisterUser/actions";
import * as Segment from "../../../utils/segment";
import PlanDescriptionOffer from "components/PlanDescriptionOffer";
import Terms from "../../../components/Terms";
import ImgWithFallback from "../../../components/ImgWithFallback";
import SelectOptions from "components/SelectOptions";
import { getAudiFilters } from "services/offers";
import { SEGMENTS, SEGMENTS_ACTIVE } from "utils/urls";
import { AiTwotonePrinter } from "react-icons/ai";
import ReactToPrint from "react-to-print";
import { IListSegmentsData, ISegment } from "utils/types";
import Loading from "components/Loading";
import ProposalPDF from "components/ProposalPDF";
import jsPDF from "jspdf";
import { DownloadOutlined } from "@ant-design/icons";
import html2canvas from "html2canvas";


interface ModelProps {
    brand: string;
    model: string;
    color: string;
    modelCode: number;
    monthlyKmValue: number;
    overrunKm: number;
    hullFranchiseValue: number;
    monthlyInstallment: number;
    deadline: number;
    deadlineInfo: string;
    optional: string;
    optionalText: string;
    modelYear: number;
    vehicleValue: number;
}

interface AudiExtras {
    accessories: any[];
    optionals: any[];
    products?: any[];
}

interface AudiAccessory {
    name: string;
    value: string;
}

const INITIAL_AUDI_EXTRAS: AudiExtras = { accessories: [], optionals: [], products: [] };

const Offer = () => {
    const hiddenContainerRef = useRef<HTMLDivElement>(null);
    const componentRef = useRef<HTMLDivElement>(null);
    const { id } = useParams<any>();
    const { addToast } = useToast();
    const { Option } = Select;
    const history = useHistory();
    const { fullname, dealershipId } = useAuth();
    const dispatch = useDispatch();

    const [loginIsRequired, setLoginIsRequired] = useState(false);
    const [filterColor, setFilterColor] = useState([]);
    const [model, setModel] = useState<ModelProps>({} as ModelProps);
    const [colorState, setColorState] = useState("");
    const [monthKmValue, setMonthKmValue] = useState(0);
    const [productId, setProductId] = useState(0);
    const [product, setProduct] = useState(null);
    const [blindagem, setBlindagem] = useState(0);
    const [totalValue, setTotalValue] = useState(0);
    const [overrunKm, setOverrunKm] = useState(0);
    const [vehicleValue, setVehicleValue] = useState(0);
    const [hullFranchiseValue, setHullFranchiseValue] = useState(0);
    const [borderColor, setBorderColor] = useState(0);
    const [modalTerm, setModalTerm] = useState(false);
    const [CPF, setCPF] = useState("");
    const [searchClient, setSearchClient] = useState(false);
    const [dealershipAudi, setDealershipAudi] = useState(false);

    const [filterOptional, setFilterOptional] = useState([]);
    const [filterMonthKM, setFilterMonthKM] = useState([]);
    const [filterDeadline, setFilterDeadline] = useState([]);

    const [filterKickback, setFilterKickback] = useState([]);

    const [deadline, setDeadline] = useState(0);
    const [personalizedJourney, setPersonalizedJourney] = useState(false);

    const [defaultValueDeadline, setDeafultValueDeadline] = useState("Selecione");
    const [defaultValueMonthKM, setDeafultValueMonthKM] = useState("Selecione");
    const [defaultValueOptional, setDeafultValueOptional] = useState("Selecione");
    const [defaultValueKickback, setDeafultValueKickback] = useState("Selecione");
    const [dealerName, setDealerName] = useState<any>(null);
    const [segmentSummary, setSegmentSummary] = useState<any>(null);
    const [segment, setSegment] = useState<any>(null);

    const [isEligibleCustomer, setIsEligibleCustomer] = useState(false);

    const [isEdit, setIsEdit] = useState(false);
    const [editData, setEditData] = useState(null);
    const [isLoadingEdit, setIsLoadingEdit] = useState(false);
    const [loadingOffers, setLoadingOffers] = useState(false);
    const [fetchPDFInfos, setFetchPDFInfos] = useState(false);

    // Audi Extras
    const [audiExtras, setAudiExtras] = useState(INITIAL_AUDI_EXTRAS);
    const [audiAccessories, setAudiAcessories] = useState<AudiAccessory[] | undefined>();
    const [audiOptionals, setAudiOptionals] = useState<AudiAccessory[] | undefined>();

    const segmentId = Number(localStorage.getItem("@FleetSolutions:segmentId"));

    useEffect(() => {
        if (localStorage.getItem("@FleetSolutions:editData")) {
            setIsEdit(true);
        } else {
            setIsEdit(false);
        }

        api.get(`/dealerships/${dealershipId}`).then((res) => {
            const { data } = res;
            setDealerName(data.name);

            const allowPessoaJuridica = data.segments && data.segments.indexOf(Segment.audiPF + ",") > -1;
            const allowPessoaJuridicaAudi = data.segments && data.segments.indexOf(Segment.audiPJ + ",") > -1;

            if (allowPessoaJuridica || allowPessoaJuridicaAudi) {
                setDealershipAudi(true);
            }
        });
    }, [dealershipId]);

    useEffect(() => {
        window.scrollTo(0, 0);
        setLoadingOffers(true);
        api.get(`filters/${id}/${segmentId}`).then((response) => {
            setFilterColor(response.data.colors);
            setFilterKickback(response.data.kickback);
        });

        // api.get(`/segments/by-id-fleet/${segmentId}`).then(({ data }) => {
        //     const firstActiveSegment = data?.find((item: { status: boolean }) => item?.status === true);
        //     setSegment()
        // });

        api.get(`offers?modelCode=${id}&segmentId=${segmentId}${Number(segmentId) === 9 ? "&salesChannel=" + encodeURIComponent(localStorage.getItem("@FleetSolutions:isSDBlindado") ? "S&D - Blindado" : "S&D") : ""}`).then((response) => {
            setLoadingOffers(false);

            const { data } = response;

            if (data.length) {
                const lowerValueOffer = data.reduce((a: ModelProps, b: ModelProps) => {
                    if (b.monthlyInstallment < a.monthlyInstallment) a = b;
                    return a;
                });

                setFilterDeadline(data.filter((v: any, i: any, a: any) => a.findIndex((t: ModelProps) => t.deadline === v.deadline) === i));

                setFilterOptional(data.filter((v: any, i: any, a: any) => a.findIndex((t: ModelProps) => t.optional === v.optional && v.optional !== "") === i));

                setFilterMonthKM(data.filter((v: any, i: any, a: any) => a.findIndex((t: any) => t.monthlyKmValue === v.monthlyKmValue) === i));

                setMonthKmValue(lowerValueOffer.monthlyKmValue);
                setDeadline(lowerValueOffer.deadline);
                setModel(lowerValueOffer);
                setTotalValue(lowerValueOffer.monthlyInstallment);
                setOverrunKm(lowerValueOffer.overrunKm);
                setHullFranchiseValue(lowerValueOffer.hullFranchiseValue);
                setProduct(lowerValueOffer);
                setProductId(lowerValueOffer.productId);
                setBlindagem(lowerValueOffer.accessoryText);
                setDeafultValueDeadline(lowerValueOffer.deadline);
                setDeafultValueMonthKM(lowerValueOffer.monthlyKmValue);
                setVehicleValue(lowerValueOffer.vehicleValue);
                setColorState(lowerValueOffer.color);
                setDeafultValueKickback(lowerValueOffer.kickback);
            }
        });
    }, []);

    useEffect(() => {
        if (productId) {
            localStorage.setItem("@FleetSolutions:productId", JSON.stringify(productId));
            loadSegments();
        }
    }, [productId]);

    // Audi Extras - buscar opções
    useEffect(() => {
        if (!personalizedJourney) return;

        let ignore = false;
        const shouldFectchAudiExtras = id && colorState && defaultValueDeadline !== "Selecione" && defaultValueMonthKM !== "Selecione";

        const shouldFetchOffers = id && colorState && defaultValueDeadline !== "Selecione" && defaultValueMonthKM !== "Selecione" && defaultValueKickback !== "Selecione";

        const fetchAudiExtras = () => {
            setLoadingOffers(true);

            setAudiAcessories(undefined); // limpar escolhas de acessórios
            getAudiFilters({
                modelCode: Number(id),
                color: colorState,
                deadLine: deadline,
                monthlyKmValue: monthKmValue,
                segmentId: segmentId,
            }).then(({ data }: any) => {
                setLoadingOffers(false);

                if (!ignore) {
                    setAudiExtras({
                        accessories: data.accessories,
                        optionals: data.optionals,
                        products: data.products,
                    });

                    if (shouldFetchOffers) {
                        fetchOffersAfterFilters(data.productId);
                    } else {
                                setProduct(data);
                                setProductId(data.productId);
                        setBlindagem(data.accessoryText);

                        setTotalValue(data.minPrice);
                        setVehicleValue(data.vehicleValue);
                        // setColorState(data.products[0].color)
                    }
                }
            });
        };

        if (shouldFectchAudiExtras) {
            fetchAudiExtras();
        } else {
            setAudiExtras(INITIAL_AUDI_EXTRAS);
            setAudiAcessories([]);
            setAudiOptionals([]);
        }
        return () => {
            ignore = true;
        };
    }, [id, colorState, defaultValueDeadline, defaultValueMonthKM, personalizedJourney]);

    const loadSegments = async () => {
        try {
            const { data } = await api.get(SEGMENTS);
            const currentSegment = JSON.parse(localStorage.getItem("@FleetSolutions:selectedSegment") || "{}") as IListSegmentsData;

            const filteredData = data.filter((segment: { iD_Fleet: number; iD_Ibratan: string }) => segment.iD_Fleet === segmentId && segment.iD_Ibratan === currentSegment?.idIbratan);
            setSegment(filteredData[0])
            setSegmentSummary(filteredData[0]?.orderSummary);
            setPersonalizedJourney(filteredData[0]?.personalizedJourney);
        } catch (error) {
            addToast({
                type: "error",
                title: "Não foi possível listar os segmentos",
                description: "Por favor, entre em contato com o suporte.",
            });
        }
    };

    const fetchOffersAfterFilters = (currentProductId: number) => {
        setLoadingOffers(true);

        api.get(
            `offers?modelCode=${model.modelCode}&color=${colorState}&deadline=${deadline}&monthlyKmValue=${monthKmValue}&kickback=${encodeURIComponent(defaultValueKickback)}&segmentId=${segmentId}${Number(segmentId) === 9 ? "&salesChannel=" + encodeURIComponent(localStorage.getItem("@FleetSolutions:isSDBlindado") ? "S&D - Blindado" : "S&D") : ""}`
        ).then((response) => {
            const { data } = response;
            setLoadingOffers(false);

            if (data.length) {
                const currentOffer = personalizedJourney && productId && data.filter((item: { productId: number }) => item.productId === currentProductId)[0] ? data.filter((item: { productId: number }) => item.productId === currentProductId)[0] : data[0];
                setProduct(currentOffer);
                setProductId(currentOffer.productId);
                setBlindagem(currentOffer.accessoryText);

                setTotalValue(currentOffer.monthlyInstallment);
                setVehicleValue(currentOffer.vehicleValue);
                setColorState(currentOffer.color);
                setOverrunKm(currentOffer.overrunKm);
                setHullFranchiseValue(currentOffer.hullFranchiseValue);
                setModel((prevModel) => ({
                    ...prevModel,
                    deadlineInfo: currentOffer?.deadlineInfo,
                }));
            } else {
                return addToast({
                    title: "Oferta não localizada.",
                    description: "Não foi possível localizar ofertas com os filtros selecionados. Altere os valores e tente novamente.",
                    type: "error",
                });
            }
        });
    };

    const fetchAudiExtrasOffers = (params: any) => {
        setLoadingOffers(true);

        const { listAccessories, listOptional, ...restParams } = params;
        let shouldFetchOffers = id && colorState && defaultValueDeadline !== "Selecione" && defaultValueMonthKM !== "Selecione" && defaultValueKickback !== "Selecione";
        if (segmentId === 26) {
            shouldFetchOffers = false;
        }
        getAudiFilters({
            ...restParams,
            listAccessories: listAccessories && listAccessories.length > 0 ? listAccessories : null,
            listOptional: listOptional && listOptional.length > 0 ? listOptional : null,
        }).then(({ data }: any) => {
            setLoadingOffers(false);

            if (data) {
                setAudiExtras({
                    accessories: data.accessories,
                    optionals: data.optionals,
                    products: data.products,
                });

                if (shouldFetchOffers) {
                    fetchOffersAfterFilters(data.productId);
                } else {
                        setProduct(data);
                        setProductId(data.productId);
                    setBlindagem(data.accessoryText);

                    setTotalValue(data.minPrice);
                    setVehicleValue(data.vehicleValue);
                    // setColorState(data.color)
                }

                return;
            }

            addToast({
                title: "Oferta não localizada.",
                description: "Não foi possível localizar ofertas com os filtros selecionados. Altere os valores e tente novamente.",
                type: "error",
            });
        });
    };

    const hasProductWithoutProductId = () => {
        if (personalizedJourney) {
            return !productId || productId === 0;
        } else {
            return false;
        }
    };

    const handleAudiAccessories = (value: any) => {
        setAudiAcessories(value);

        fetchAudiExtrasOffers({
            modelCode: Number(id),
            color: colorState,
            deadLine: deadline,
            monthlyKmValue: monthKmValue,
            segmentId: segmentId,
            listAccessories: value,
            listOptional: audiOptionals,
        });
    };

    const handleCustomBlindagem = (value: any) => {
        setAudiAcessories(value);

        fetchAudiExtrasOffers({
            modelCode: Number(id),
            color: colorState,
            deadLine: deadline,
            monthlyKmValue: monthKmValue,
            segmentId: segmentId,
            listAccessories: [value],
            listOptional: audiOptionals,
        });
    };

    const handleAudiOptionals = (value: any) => {
        setAudiOptionals(value);

        fetchAudiExtrasOffers({
            modelCode: Number(id),
            color: colorState,
            deadLine: deadline,
            monthlyKmValue: monthKmValue,
            segmentId: segmentId,
            listAccessories: audiAccessories,
            listOptional: value,
        });
    };

    const handleCustomOptionals = (value: any) => {
        const data: any = audiExtras.products?.filter((item) => item.productId === value.productId);

setProduct(data[0]);
setProductId(data[0]?.productId);
        setBlindagem(data[0]?.accessoryText);

        setTotalValue(data[0]?.monthlyInstallment);
        setVehicleValue(data[0]?.vehicleValue);
        setColorState(data[0]?.color);
        setOverrunKm(data[0]?.overrunKm);
        setHullFranchiseValue(data[0]?.hullFranchiseValue);
        setModel((prevModel) => ({
            ...prevModel,
            deadlineInfo: data[0]?.deadlineInfo,
        }));
    };

    const handleChangeCpf = (e: any) => {
        setCPF(cpfMask(e.target.value));
    };

    const handleChangeOptional = async (optional: string) => {
        if (colorState === "") {
            return addToast({
                title: "Selecione a cor do veículo",
                description: "Primeiro selecione a cor do veículo desejado.",
                type: "error",
            });
        }
        setLoadingOffers(true);

        await api
            .get(
                `offers?modelCode=${model.modelCode}&color=${colorState}&deadline=${deadline}&monthlyKmValue=${monthKmValue}&optional=${optional}&segmentId=${segmentId}${Number(segmentId) === 9 ? "&salesChannel=" + encodeURIComponent(localStorage.getItem("@FleetSolutions:isSDBlindado") ? "S&D - Blindado" : "S&D") : ""}`
            )
            .then((response) => {
                setLoadingOffers(false);

                const { data } = response;

                if (data.length) {
                        setProduct(data[0]);
                        setProductId(data[0].productId);
                    setBlindagem(data[0].accessoryText);

                    setTotalValue(data[0].monthlyInstallment);
                    setVehicleValue(data[0].vehicleValue);
                    setColorState(data[0].color);
                    setOverrunKm(data[0].overrunKm);
                    setHullFranchiseValue(data[0].hullFranchiseValue);
                    setModel((prevModel) => ({
                        ...prevModel,
                        deadlineInfo: data[0]?.deadlineInfo,
                    }));
                } else {
                    return addToast({
                        title: "Oferta não localizada.",
                        description: "Não foi possível localizar ofertas com os filtros selecionados. Altere os valores e tente novamente.",
                        type: "error",
                    });
                }
            });

        setDeafultValueOptional(optional);
    };

    const handleFindClient = () => {
        localStorage.setItem("@FleetSolutions:cpfClient", CPF);

        const clearCpfCnpj = CPF.replace(".", "").replace(".", "").replace("-", "");
        setCPF(clearCpfCnpj);

        api.post("/signup/signUp-pf", { cpf: clearCpfCnpj })
            .then((response: any) => {
                const { userId } = response.data;
                localStorage.setItem("@FleetSolutions:userIdPf", userId);
                history.push(`/list-dealership/upload-file/${CPF}`);
                dispatch(setTabs({ id: "1" }));
            })
            .catch(() => {
                addToast({
                    title: "CPF inválido!",
                    type: "error",
                    description: "Digite um CPF válido",
                });
            });
    };

    const handleFilterColor = useCallback(
        async (color: string, colorCode: number) => {
            setLoadingOffers(true);

            setBorderColor(colorCode);
            await api.get(`offers?modelCode=${model.modelCode}&color=${color}&segmentId=${segmentId}${Number(segmentId) === 9 ? "&salesChannel=" + encodeURIComponent(localStorage.getItem("@FleetSolutions:isSDBlindado") ? "S&D - Blindado" : "S&D") : ""}`).then((response) => {
                setLoadingOffers(false);

                const { data } = response;

                setFilterDeadline(data.filter((v: any, i: any, a: any) => a.findIndex((t: any) => t.deadline === v.deadline) === i));

                setFilterMonthKM(data.filter((v: any, i: any, a: any) => a.findIndex((t: any) => t.monthlyKmValue === v.monthlyKmValue) === i));

                setFilterOptional(data.filter((v: any, i: any, a: any) => a.findIndex((t: any) => t.optional === v.optional && v.optional !== "") === i));

                setModel((prevModel) => ({
                    ...prevModel,
                    deadlineInfo: data[0]?.deadlineInfo,
                }));

                setProduct(data[0])
                setProductId(data[0]?.productId)

            });
            setColorState(color);
            setDeafultValueDeadline("Selecione");
            setDeafultValueMonthKM("Selecione");
            setDeafultValueOptional("Selecione");
            setDeafultValueKickback("Selecione");
            setAudiExtras(INITIAL_AUDI_EXTRAS);
            setAudiAcessories([]);
            setAudiOptionals([]);
        },
        [model, colorState, deadline, monthKmValue]
    );

    const handleFilterContract = useCallback(
        async (deadline: any) => {
            if (colorState === "") {
                return addToast({
                    title: "Selecione a cor do veículo",
                    description: "Primeiro selecione a cor do veículo desejado.",
                    type: "error",
                });
            }
            setLoadingOffers(true);

            await api
                .get(`offers?modelCode=${model.modelCode}&color=${colorState}&deadline=${deadline}&segmentId=${segmentId}${Number(segmentId) === 9 ? "&salesChannel=" + encodeURIComponent(localStorage.getItem("@FleetSolutions:isSDBlindado") ? "S&D - Blindado" : "S&D") : ""}`)
                .then((response) => {
                    setLoadingOffers(false);

                    const { data } = response;

                    if (data.length) {
                                setProduct(data[0]);
                                setProductId(data[0].productId);
                        setBlindagem(data[0].accessoryText);

                        setTotalValue(data[0].monthlyInstallment);
                        setVehicleValue(data[0].vehicleValue);
                        setColorState(data[0].color);
                        setOverrunKm(data[0].overrunKm);
                        setHullFranchiseValue(data[0].hullFranchiseValue);
                        setModel((prevModel) => ({
                            ...prevModel,
                            deadlineInfo: data[0]?.deadlineInfo,
                        }));
                        setFilterMonthKM(data.filter((v: any, i: any, a: any) => a.findIndex((t: any) => t.monthlyKmValue === v.monthlyKmValue) === i));
                    } else {
                        return addToast({
                            title: "Oferta não localizada",
                            description: "Não foi possível localizar ofertas com os filtros selecionados. Altere os valores e tente novamente.",
                            type: "error",
                        });
                    }
                });

            setDeadline(deadline);
            setDeafultValueDeadline(deadline);
            setDeafultValueMonthKM("Selecione");
            setDeafultValueOptional("Selecione");
            setDeafultValueKickback("Selecione");
            setAudiExtras(INITIAL_AUDI_EXTRAS);
            setAudiAcessories([]);
            setAudiOptionals([]);
        },
        [model, colorState, deadline, monthKmValue]
    );

    const handleFranchise = useCallback(
        async (kmValue) => {
            if (colorState === "") {
                return addToast({
                    title: "Selecione a cor do veículo",
                    description: "Primeiro selecione a cor do veículo desejado.",
                    type: "error",
                });
            }
            setLoadingOffers(true);

            await api
                .get(
                    `offers?modelCode=${model.modelCode}&color=${colorState}&deadline=${deadline}&monthlyKmValue=${kmValue}&segmentId=${segmentId}${Number(segmentId) === 9 ? "&salesChannel=" + encodeURIComponent(localStorage.getItem("@FleetSolutions:isSDBlindado") ? "S&D - Blindado" : "S&D") : ""}`
                )
                .then((response: any) => {
                    const { data } = response;
                    setLoadingOffers(false);

                    if (data.length) {
                                setProduct(data[0]);
                                setProductId(data[0].productId);
                        setBlindagem(data[0].accessoryText);

                        setTotalValue(data[0].monthlyInstallment);
                        setVehicleValue(data[0].vehicleValue);
                        setColorState(data[0].color);
                        setOverrunKm(data[0].overrunKm);
                        setHullFranchiseValue(data[0].hullFranchiseValue);
                        setFilterOptional(data.filter((v: any, i: any, a: any) => a.findIndex((t: any) => t.optional === v.optional && v.optional !== "") === i));
                        setModel((prevModel) => ({
                            ...prevModel,
                            deadlineInfo: data[0]?.deadlineInfo,
                        }));
                    } else {
                        return addToast({
                            title: "Oferta não localizada.",
                            description: "Não foi possível localizar ofertas com os filtros selecionados. Altere os valores e tente novamente.",
                            type: "error",
                        });
                    }
                });

            setMonthKmValue(kmValue);
            setDeafultValueOptional("Selecione");
            setDeafultValueKickback("Selecione");
            setDeafultValueMonthKM(kmValue);
            setAudiExtras(INITIAL_AUDI_EXTRAS);
            setAudiAcessories([]);
            setAudiOptionals([]);
        },
        [model, colorState, deadline, monthKmValue]
    );

    const handleContinueOrder = useCallback(() => {
        const requiredFields = [colorState, defaultValueMonthKM, defaultValueKickback];
        const emptyValues = ["", "Selecione"];

        if (!personalizedJourney) {
            if (filterOptional.length > 0) requiredFields.push(defaultValueOptional);
        } else {
            if (!productId || productId === 0) {
                return addToast({
                    title: "Atenção",
                    description: "Nenhuma oferta foi localizada para as opções selecionadas.",
                    type: "error",
                });
            }
        }

        const disabledBtn = requiredFields.find((requiredItem) => {
            return emptyValues.includes(requiredItem);
        });

        if (disabledBtn) {
            return addToast({
                title: "Selecione todas as opções.",
                description: "É necessiário selecionar todas as informações da oferta para continuar.",
                type: "error",
            });
        }

        if (fullname === undefined) {
            setLoginIsRequired(true);
            dispatch(loginApp({ status: true }));
        } else {
            setSearchClient(true);
        }
    }, [colorState, defaultValueOptional, defaultValueMonthKM, defaultValueKickback, fullname, addToast, dispatch, productId]);

    const handleEditOrder = () => {
        setIsLoadingEdit(true);
        let editData = JSON.parse(localStorage.getItem("@FleetSolutions:editData")!);
        let editPayload = editData;
        let orderId = editData.orderId;
        editPayload = { ...editPayload, productId: productId };
        delete editPayload.orderId;
        api.put("/orderitems", editPayload).then((res) => {
            addToast({
                title: "Pedido Atlerado!",
                type: "success",
                description: "Pedido editado com sucesso.",
            });
            history.push(`/order-status-pf/${orderId}`);
            localStorage.removeItem("@FleetSolutions:editData");
            setIsLoadingEdit(false);
        });
    };

    // const showModalTerm = () => {
    //   setModalTerm(true)
    // }

    const closeModalTerm = () => {
        setModalTerm(false);
    };

    const handleChangeFilterByKickback = async (kickback: string) => {
        const selectedKickback = kickback[0];

        if (kickback === "") {
            return addToast({
                title: "Selecione a comissão do veículo",
                description: "Primeiro selecione a comissão do veículo desejado.",
                type: "error",
            });
        }
        setLoadingOffers(true);

        const url =
            defaultValueOptional !== "Selecione"
                ? `offers?modelCode=${model.modelCode}&color=${colorState}&deadline=${deadline}&monthlyKmValue=${monthKmValue}&optional=${defaultValueOptional}&kickback=${encodeURIComponent(
                      selectedKickback
                  )}&segmentId=${segmentId}${Number(segmentId) === 9 ? "&salesChannel=" + encodeURIComponent(localStorage.getItem("@FleetSolutions:isSDBlindado") ? "S&D - Blindado" : "S&D") : ""}`
                : `offers?modelCode=${model.modelCode}&color=${colorState}&deadline=${deadline}&monthlyKmValue=${monthKmValue}&kickback=${encodeURIComponent(selectedKickback)}&segmentId=${segmentId}${Number(segmentId) === 9 ? "&salesChannel=" + encodeURIComponent(localStorage.getItem("@FleetSolutions:isSDBlindado") ? "S&D - Blindado" : "S&D") : ""}`;

        await api.get(url).then((response) => {
            const { data } = response;
            setLoadingOffers(false);

            if (data.length) {
                const currentOffer = personalizedJourney && productId && data.filter((item: { productId: number }) => item.productId === productId)[0] ? data.filter((item: { productId: number }) => item.productId === productId)[0] : data[0];
                setProduct(currentOffer);
                setProductId(currentOffer.productId);
                setBlindagem(currentOffer.accessoryText);

                setTotalValue(currentOffer.monthlyInstallment);
                setVehicleValue(currentOffer.vehicleValue);
                setColorState(currentOffer.color);
                setOverrunKm(currentOffer.overrunKm);
                setHullFranchiseValue(currentOffer.hullFranchiseValue);
                setModel((prevModel) => ({
                    ...prevModel,
                    deadlineInfo: currentOffer?.deadlineInfo,
                }));
            } else {
                return addToast({
                    title: "Oferta não localizada.",
                    description: "Não foi possível localizar ofertas com os filtros selecionados. Altere os valores e tente novamente.",
                    type: "error",
                });
            }
        });

        setDeafultValueKickback(selectedKickback);
    };

    const createColorsOption = () => (
        <Colors>
            <div className="color-options">
                <span>Metálico</span>
                <div>
                    {filterColor.length
                        ? filterColor.map((item: any, key: number) => {
                              if (item.color.includes("Metálica") || item.color.includes("Metálico") || item.color.includes("Perolizada")) {
                                  return (
                                      <div className="color-type" key={key}>
                                          {item.colorCode && (
                                              <img
                                                  style={{
                                                      border: borderColor === item.colorCode ? "2px solid #1890ff" : "",
                                                  }}
                                                  onClick={() => handleFilterColor(item.color, item.colorCode)}
                                                  title={item.color}
                                                  onError={(e: any) => {
                                                      e.target.onerror = null;
                                                      e.target.src = noImageColor;
                                                  }}
                                                  src={`${defaultBackendUrl}/s3/veiculos/Cores/${item.colorCode}.png`}
                                                  alt="Cor"
                                              />
                                          )}
                                      </div>
                                  );
                              }
                          })
                        : null}
                </div>
            </div>
            <div className="color-options">
                <span>Sólida</span>

                <div>
                    {filterColor.length
                        ? filterColor.map((item: any, key: number) => {
                              if (!item.color.includes("Metálica") && !item.color.includes("Metálico") && !item.color.includes("Perolizada")) {
                                  return (
                                      <div className="color-type" key={key}>
                                          <img
                                              style={{
                                                  border: borderColor === item.colorCode ? "2px solid #1890ff" : "",
                                              }}
                                              onClick={() => handleFilterColor(item.color, item.colorCode)}
                                              title={item.color}
                                              onError={(e: any) => {
                                                  e.target.onerror = null;
                                                  e.target.src = noImageColor;
                                              }}
                                              src={`${defaultBackendUrl}/s3/veiculos/Cores/${item.colorCode}.png`}
                                              alt="Cor"
                                          />
                                      </div>
                                  );
                              }
                          })
                        : null}
                </div>
            </div>
        </Colors>
    );

    const handleChangeEligible = () => {
        history.push(`/list-dealership/upload-file/${CPF}`);
        dispatch(setTabs({ id: "1" }));
        setIsEligibleCustomer(true);
    };

    const handleGeneratePdf = () => {
        if (hiddenContainerRef.current) {
            setFetchPDFInfos(true)
            // Usa html2canvas para capturar a altura real do conteúdo HTML
            html2canvas(hiddenContainerRef.current, {
                scale: 1,
                useCORS: true // Permite o uso de imagens de outras origens
            }).then((canvas) => {
                const contentWidth = canvas.width;
                const contentHeight = canvas.height;
    
                // Define as dimensões do PDF com base no conteúdo
                const pdfWidth = contentWidth; 
                const pdfHeight = contentHeight;
    
                // Cria o PDF com o tamanho ajustado para evitar múltiplas páginas
                const doc:any = new jsPDF({
                    orientation: 'portrait',
                    unit: 'pt',
                    format: [pdfWidth, pdfHeight], // Define o tamanho do PDF para caber todo o conteúdo
                });
    
                // Adiciona o conteúdo HTML ao PDF
                doc.setFont("Open Sans", "normal");
                doc.html(hiddenContainerRef.current as HTMLElement, {
                    x: 0,
                    y: 0,
                    html2canvas: {
                        scale: 1,
                        useCORS: true, // Permite cross-origin
                    },
                    callback: function (doc:any) {
                        // Ajuste final: removendo páginas em branco
                        const totalPages = doc?.internal?.getNumberOfPages();
                        if (totalPages > 1) {
                            doc.deletePage(totalPages); // Remove a última página em branco
                        }
    
                        // Salva o PDF com todo o conteúdo em uma única página
                        doc.save("Proposta_Comercial_Canal_Indireto.pdf");
                        setFetchPDFInfos(false)
                    },
                });
            });
        }
    };
    
    
    
    

    const proposalDate = () => {
        const currentDate = new Date();

        const day = currentDate.getDate().toString().padStart(2, "0");
        const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
        const year = currentDate.getFullYear().toString();

        const hours = currentDate.getHours().toString().padStart(2, "0");
        const minutes = currentDate.getMinutes().toString().padStart(2, "0");

        const formattedDateTime = `${day}/${month}/${year} - ${hours}:${minutes}`;

        return formattedDateTime;
    };

    return (
        <>
            <Header />
            <div ref={hiddenContainerRef} style={{ position: "absolute", zIndex: "-9999999999", overflow: "hidden", top: 0 }}>
                {segment && <ProposalPDF parameters={{productItem:product ,product:productId, model:model, seller:fullname, color:colorState, dealer:dealerName, deadline:deadline, monthKmValue:monthKmValue, totalValue:formatValue(totalValue), contractValue: formatValue(vehicleValue), overrunKM: formatValue(overrunKm), segmentSummary: segmentSummary, proposalDate: proposalDate(), segment:segment, extraModelItems:audiExtras}} />
            }

            </div>
            <Container>
                <Content className={segmentId === 26 ? "custom-optionals-flow" : ""}>
                    {loginIsRequired && <SignIn />}

                    {model.modelCode && <ImageCar id="imagem-oferta" src={`${defaultBackendUrl}/s3/veiculos/canal-indireto/${model.modelCode}.webp`} fallbacks={[`${defaultBackendUrl}/s3/veiculos/canal-indireto/${model.modelCode}.png`, `${noImageCar}`]} alt={model.model} />}

                    <DetailsCar className={segmentId === 26 ? "custom-optionals-details" : ""}>
                        <h1>{model.model}</h1>
                        {!dealershipAudi && <span className="description-item">{model.model !== undefined ? model.model.substring(model.model.split(" ")[0].length, model.model.length) : null}</span>}

                        <div className="reservation-params">
                            {loadingOffers && (
                                <div className="loader">
                                    <Loading text={""} />
                                </div>
                            )}

                            <div className="colors">
                                <p>Cores disponíveis</p>
                                {createColorsOption()}
                            </div>
                            <div className="plan">
                                <div>
                                    <p className="title-option">Período</p>
                                    {deadline !== 0 && (
                                        <Select onChange={handleFilterContract} style={{ width: 120, border: "1px solid #0C5FB1" }} value={defaultValueDeadline}>
                                            {filterDeadline
                                                .sort((a: any, b: any) => a.deadline - b.deadline)
                                                .map((item: ModelProps, idx: number) => (
                                                    <option key={idx} value={item.deadline}>
                                                        {item.deadline} meses
                                                    </option>
                                                ))}
                                        </Select>
                                    )}
                                </div>
                                <div>
                                    <p className="title-option">Franquia Km/mês</p>
                                    {monthKmValue !== 0 && (
                                        <Select onChange={handleFranchise} value={defaultValueMonthKM} style={{ width: 120, border: "1px solid #0C5FB1" }}>
                                            {filterMonthKM
                                                .sort((a: any, b: any) => a.monthlyKmValue - b.monthlyKmValue)
                                                .map((item: ModelProps, idx: number) => (
                                                    <option key={idx} value={item.monthlyKmValue}>{`${item.monthlyKmValue} KM`}</option>
                                                ))}
                                        </Select>
                                    )}
                                </div>
                            </div>

                            {personalizedJourney ? (
                                <>
                                    {segmentId === 26 ? (
                                        <>
                                            {audiExtras.optionals.length > 0 && (
                                                <>
                                                    <div className="options-offer custom-optionals">
                                                        <>
                                                            <p>Opcionais</p>

                                                            {audiExtras?.products
                                                                ?.filter((item) => {
                                                                    // Verifica se há itens selecionados
                                                                    const hasSelectedAccessory = audiAccessories && audiAccessories.length;

                                                                    // Aplica a filtragem com base na presença de itens selecionados
                                                                    return hasSelectedAccessory ? item.optional.toLowerCase().includes("blindagem") : !item.optional.toLowerCase().includes("blindagem");
                                                                })
                                                                .map((item, idx) => {
                                                                    const formattedText = "- " + item.optionalText.replace(/ ; /g, " ;<br/>- ");

                                                                    return (
                                                                        <div onClick={() => handleCustomOptionals(item)} className={productId === item.productId ? "optional-group selected" : "optional-group"}>
                                                                            <div className="head-group">
                                                                                <Checkbox checked={productId === item.productId} onChange={() => handleCustomOptionals(item)}></Checkbox>
                                                                                <h4>Pacote de opcionais {idx + 1}</h4>
                                                                            </div>
                                                                            <span dangerouslySetInnerHTML={{ __html: formattedText }} />
                                                                        </div>
                                                                    );
                                                                })}
                                                        </>
                                                    </div>
                                                </>
                                            )}
                                            {audiExtras.accessories.length > 0 && (
                                                <>
                                                    <div className="options-offer">
                                                        <p>Blindagem</p>

                                                        <div className="options-offer">
                                                            <SelectOptions data={audiExtras.accessories} onChange={handleAudiAccessories} />
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {audiExtras.accessories.length > 0 && (
                                                <>
                                                    <div className="colors">
                                                        <p>Acessórios</p>
                                                    </div>
                                                    <div className="options-offer">
                                                        <SelectOptions data={audiExtras.accessories} onChange={handleAudiAccessories} />
                                                    </div>
                                                </>
                                            )}

                                            {audiExtras.optionals.length > 0 && (
                                                <>
                                                    <div className="colors">
                                                        <p>Opcionais</p>
                                                    </div>
                                                    <div className="options-offer">
                                                        <SelectOptions data={audiExtras.optionals} onChange={handleAudiOptionals} />
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    )}
                                </>
                            ) : (
                                <div className="options-offer">
                                    {filterOptional.length > 0 && (
                                        <>
                                            <p>Opcionais</p>
                                            <Select style={{ width: 269, border: "1px solid #0C5FB1" }} onChange={handleChangeOptional} value={defaultValueOptional}>
                                                {filterOptional.map((item: ModelProps, idx: number) => (
                                                    <option key={idx} value={item.optional}>
                                                        {item.optionalText}
                                                    </option>
                                                ))}
                                            </Select>
                                        </>
                                    )}
                                </div>
                            )}

                            <div className="options-offer">
                                <p>Comissão</p>
                                <Select style={{ width: 269, border: "1px solid #0C5FB1" }} onChange={handleChangeFilterByKickback} value={defaultValueKickback}>
                                    {filterKickback &&
                                        filterKickback
                                            .sort((a: any, b: any) => {
                                                if (a.kickback < b.kickback) {
                                                    return -1;
                                                }
                                                if (a.kickback > b.kickback) {
                                                    return 1;
                                                }
                                                return 0;
                                            })
                                            .map((name: any, idx: number) => (
                                                <Option key={idx} value={name}>
                                                    {name}
                                                </Option>
                                            ))}
                                </Select>
                            </div>
                        </div>

                        <div className="info-important">
                            <div className="surplus-km">
                                <div className="info">
                                    <p>Custo/Km Excedente</p>
                                    <Tooltip color={"#236fbc"} placement="top" title="Para km excedente será aplicado um custo adicional considerando a variável fixa do custo/km excedente em relação às regras de contrato.">
                                        <img src={iconInfo} alt="icone-informacoes" />
                                    </Tooltip>
                                </div>

                                <span>{formatValue(overrunKm)}</span>
                            </div>
                            <div className="surplus-franchise">
                                <div className="info">
                                    <p>Coparticipação em sinistro:</p>
                                    <Tooltip color={"#236fbc"} placement="top" title="Refere-se ao valor que deverá ser pago em situações de sinistros de colisões de perda parcial, PT, furto e roubo">
                                        <img src={iconInfo} alt="icone-informacoes" />
                                    </Tooltip>
                                </div>
                                <span>5% do preço público do veículo</span>
                            </div>
                        </div>
                        <AlignLeft>
                            <div className="value-plan">
                                <ValuePlan>{formatValue(totalValue)}/mês</ValuePlan>
                            </div>
                            {
                                // eslint-disable-next-line
                                // <a onClick={showModalTerm}>Ver termos e Condições</a>
                            }
                        </AlignLeft>
                    </DetailsCar>
                </Content>

                <PlanDescriptionOffer model={model.model} totalValue={totalValue} deadline={deadline} monthKmValue={monthKmValue} deliveryDeadline={model.deadlineInfo} />
                <ButtonLine>
                    <button className="btn-back" onClick={() => history.goBack()}>
                        Voltar
                    </button>

                    <div className="wrapper-print">
                        {/* <ReactToPrint
                            trigger={() => (
                                <button className="btn-print">
                                    <div className="print">
                                        <AiTwotonePrinter color="#236FBC" size={25} /> Imprimir
                                    </div>
                                </button>
                            )}
                            content={() => componentRef.current}
                        /> */}
                                <button onClick={() => handleGeneratePdf()} className="btn-print">
                                    <div className="print">
                                        <DownloadOutlined color="#236FBC" size={30} /> Exportar PDF da Proposta
                                    </div>
                                </button>
                    </div>

                    <Button className="btn-continue" onClick={isEdit ? handleEditOrder : handleContinueOrder} disabled={hasProductWithoutProductId() || isLoadingEdit || loadingOffers}>
                        {!isEdit ? "Alugar agora" : isLoadingEdit ? "Salvando..." : "Editar pedido"}
                    </Button>
                </ButtonLine>

                <Modal title="" visible={modalTerm} onOk={closeModalTerm} cancelButtonProps={{ hidden: true }}>
                    <Terms />
                </Modal>
                <Modal width="368px" title="" onCancel={() => setSearchClient(false)} footer={null} visible={searchClient}>
                    <ContentModal>
                        <h3>Pesquisar cliente</h3>
                        <p>Digite o CPF para identificar um cliente.</p>
                        <Input className="input" id="search-client" type="text" name="search-client" placeholder="CPF" onChange={handleChangeCpf} value={CPF} />

                        <Button onClick={handleFindClient}>Pesquisar</Button>
                    </ContentModal>
                </Modal>
            </Container>
            <Container className="printable" ref={componentRef}>
                <ImageCar src={`${defaultBackendUrl}/s3/veiculos/canal-indireto/${model.modelCode}.webp`} fallbacks={[`${defaultBackendUrl}/s3/veiculos/canal-indireto/${model.modelCode}.png`, `${noImageCar}`]} alt={model.model} />

                <div className="infos-group">
                    <div className="title">Informações do veículo:</div>
                    <div className="items">
                        <div className="item">
                            <span>Modelo:</span>
                            {!isEmpty(model) ? model.model : ""}
                        </div>
                        <div className="item">
                            <span>Cor:</span>
                            {!isEmpty(model) ? colorState : ""}
                        </div>
                        <div className="item">
                            <span>Ano:</span>
                            {!isEmpty(model) ? model.modelYear : ""}
                        </div>
                    </div>
                </div>

                <div className="infos-group">
                    <div className="title">Informações do contrato:</div>
                    <div className="items">
                        <div className="item">
                            <span>Período:</span>
                            {!isEmpty(model) ? deadline : ""} meses
                        </div>
                        <div className="item">
                            <span>Franquia mensal:</span>
                            {!isEmpty(model) ? monthKmValue : ""} Kms
                        </div>
                        <div className="item">
                            <span>Total da parcela:</span>
                            {formatValue(totalValue)}
                        </div>
                        <div className="item">
                            <span>Total do contrato:</span>
                            {formatValue(vehicleValue)}
                        </div>
                        <div className="item">
                            <span>Valor do KM excedente:</span>
                            {formatValue(overrunKm)}
                        </div>
                        <div className="item">
                            <span>Coparticipação em sinistro:</span>
                            {formatValue(hullFranchiseValue)}
                        </div>
                    </div>
                </div>
                {segmentSummary && (
                    <div className="infos-group">
                        <div className="title">Resumo do pedido</div>
                        <div className="items">
                            <div className="item">
                                <p dangerouslySetInnerHTML={{ __html: segmentSummary }} />
                            </div>
                        </div>
                    </div>
                )}
                <div className="infos-group footer-group">
                    <div className="items">
                        <div className="item">
                            <span>Nome da Concessionária:</span>
                            {dealerName}
                        </div>
                        <div className="item">
                            <span>Nome do Vendedor:</span>
                            {fullname}
                        </div>
                        <div className="item">
                            <span>Data/hora da cotação:</span>
                            {proposalDate()}
                        </div>
                    </div>
                </div>
            </Container>
            <Footer />
        </>
    );
};

export default Offer;
