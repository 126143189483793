/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { AiTwotonePrinter } from "react-icons/ai";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Container, Content, WrapperLine, Summary, Notes } from "./styles";
import Button from "../../components/Button";
import { useAuth } from "../../hooks/auth";
import api from "../../services/api";
import { listProducts } from "../../utils/listProducts";
import formatValue from "../../utils/formatValue";
import { cpfMask } from "../../utils/cpfMask";
import { cnpjMask } from "../../utils/cnpjMask";
import Pagination from "../../components/Pagination";
import { setTabs } from "../../store/modules/tabsRegisterUser/actions";
import { defaultBackendUrl } from "services/api";
import * as Segment from "../../utils/segment";
import ImgWithFallback from "../../components/ImgWithFallback";
import noImageCar from "assets/defaultImages/no-image.png";
import ReactToPrint from "react-to-print";
import { DownloadOutlined } from "@ant-design/icons";
import jsPDF from "jspdf";
import ProposalPDF from "components/ProposalPDF";
import html2canvas from "html2canvas";
import { IState } from "store";
import { ILocation } from "store/modules/location/types";

interface IUser {
    cnpj: string;
    contactName: string;
    mail: string;
    companyName: string;
    cpf: string;
    phone: string;
    address: {
        street: string;
        number: string;
        district: string;
        city: string;
        complement: string;
    };
}

interface iPropsProducts {
    color: string;
    productId: number;
    quantity: number;
    modelCode: number;
    model: string;
    optional: string;
    deadline: number;
    monthlyKmValue: number;
    monthlyInstallment: number;
    totalRequestCredit: number;
    hullFranchiseValue: string;
    overrunKm: number;
    isDNPickupLocation: string;
    audiAccessories: string;
    audiOptionals: string;
}

const OrderSummer = () => {
    const componentRef = useRef<HTMLDivElement>(null);
    const dateNow = new Date();
    const dispatch = useDispatch();
    const tabId = useSelector<IState, ILocation>((state) => state.tabs);

    const [user, setUser] = useState({} as IUser);
    const { fullname, dealershipId, userId } = useAuth();
    const history = useHistory();

    const [nameDealership, setNameDealership] = useState("");
    const [dealership, setDealership] = useState<any>("");
    const [productsData, setProductsData] = useState<iPropsProducts[]>([]);
    const [allProductsData, setAllProductsData] = useState<iPropsProducts[]>([]);
    const [allOrderItems, setAllOrderItems] = useState<any[]>([]);
    const [proposalProductsData, setProposalProductsData] = useState<any[]>([]);
    const [totalMonthlyInstallment, setTotalMonthlyInstallment] = useState(0);
    const [requestCredit, setRequestCredit] = useState(0);
    const [longerTerm, setLongerTerm] = useState(0);
    const [dealershipAudi, setDealershipAudi] = useState(false);
    const [resumeText, setResumeText] = useState("");
    const [segment, setSegment] = useState<any>();
    const [originalCartStorage, setOriginalCartStorage] = useState<any>(localStorage.getItem("@FleetSolutions:cart"));

    const deliveryOption = Number(localStorage.getItem("@FleetSolutions:selectedDelivery"));

    const [perPage] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(0);
    
    const hiddenContainerRef = useRef<HTMLDivElement>(null);

    const [showPDFComponent, setShowPDFComponent] = useState(false);


    useEffect(() => {
        if (!userId) {
            history.push("/");
        }

        api.get(`/dealerships/${dealershipId}`).then((res) => {
            const { data } = res;
            setNameDealership(data.name);
            setDealership(data);
            const allowPJAudi = data.segments && data.segments.indexOf(Segment.audiPJ + ",") > -1;

            if (allowPJAudi) {
                setDealershipAudi(true);
            }
        });

        api.get(`/segments`).then((res) => {
            const currentSegment = Number(localStorage.getItem("@FleetSolutions:segmentId"));
            const { data } = res;
            const segment = data.filter((segment: { iD_Fleet: number; }) => segment.iD_Fleet === currentSegment)[0];
            setResumeText(segment?.orderSummary)
            setSegment(segment)
        });


    }, []);

    useEffect(() => {
        
        const cnpjCompany = localStorage.getItem("@FleetSolutions:cnpjCompany");
        const productsLocal = localStorage.getItem("@FleetSolutions:cart");
        const productsLocalProposal = localStorage.getItem("@FleetSolutions:cartProposal");
        const orderItems = localStorage.getItem("@FleetSolutions:orderItems");



        if(orderItems){
            const items = JSON.parse(orderItems)
            setAllOrderItems(items)
        }
        if(productsLocalProposal){
            const products = JSON.parse(productsLocalProposal);

            setProposalProductsData(products)

        }


        if(originalCartStorage && typeof originalCartStorage === "string"){
            const storage = JSON.parse(originalCartStorage);

            setOriginalCartStorage(storage)

        }

        const indexOfLastProduct = currentPage * perPage;
        const indexOfFirstProduct = indexOfLastProduct - perPage;

        if (productsLocal) {
            const products = JSON.parse(productsLocal);

            // eslint-disable-next-line prefer-spread
            const maxTerm = Math.max.apply(
                Math,
                products.map(function (o: iPropsProducts) {
                    return o.deadline;
                })
            );

            setLongerTerm(maxTerm);

            const totalRequestCreditOfProducts = products.map((item: iPropsProducts) => item.totalRequestCredit);

            const removeDuplicateValue = totalRequestCreditOfProducts.filter((value: number, index: number) => {
                return totalRequestCreditOfProducts.indexOf(value) === index;
            });

            const totalRequest = removeDuplicateValue.reduce((acc: number, cur: number) => acc + cur);

            setRequestCredit(totalRequest);

            if (products[0].quantity) {
                const totalInstallment = products.map((item: iPropsProducts) => {
                    return item.quantity * item.monthlyInstallment;
                });
                setTotalMonthlyInstallment(totalInstallment.reduce((acc: number, val: number) => acc + val, 0));
                const newData = products.map((item: iPropsProducts) => {
                    return listProducts(item);
                });

                const data: any = [];
                newData.forEach((item: any, index: number) => {
                    data.push(...newData[index]);
                });

                setTotal(data.length);

                const currentProducts = data.slice(indexOfFirstProduct, indexOfLastProduct);

                setProductsData(currentProducts);
                setAllProductsData(data);
            } else {
                const currentProducts = products.slice(indexOfFirstProduct, indexOfLastProduct);
                const totalInstallment = products.map((item: iPropsProducts) => {
                    return item.monthlyInstallment;
                });

                setTotalMonthlyInstallment(totalInstallment.reduce((acc: number, val: number) => acc + val, 0));
                setTotal(products.length);
                setProductsData(currentProducts);
                setAllProductsData(products);
            }
        }
        if (cnpjCompany) {
            api.get(`/company/${cnpjCompany.replace(/\D/g, "")}`).then((res) => {
                setUser(res.data);
            });
        }
    }, [currentPage, perPage, tabId]);

    const handleContinueOrder = (id: string) => {
        dispatch(setTabs({ id: id }));
    };

    const formatPhone = (value: any) => {
        if (value.length === 10) {
            return value.replace(/(\d{2})(\d)/, "($1) $2").replace(/(\d{4})(\d)/, "$1-$2");
        } else {
            return value.replace(/(\d{2})(\d)/, "($1) $2").replace(/(\d{5})(\d)/, "$1-$2");
        }
    };

    const showExpirationDate = () => {
        const dayMonth = dateNow.getUTCDate() + 10;

        if (dayMonth < 30 || dayMonth < 31) {
            return dayMonth;
        } else {
            return 30;
        }
    };

    const getAccessories = (value: any) => {
        let accessories = "";

        if (!value) return;

        value.split("|").forEach((ite: string) => {
            if (ite.indexOf("acs") > -1) {
                accessories += ite.replace("acs=", "") + "; ";
            }
        });

        return accessories;
    };

    const getOptionals = (value: any) => {
        let optionals = "";

        if (!value) return;

        value.split("|").forEach((ite: string) => {
            if (ite.indexOf("opt") > -1) {
                optionals += ite.replace("opt=", "") + "; ";
            }
        });

        return optionals;
    };

    const getDealerAddress = (item:any) => {
        return `${item?.dealerInfos?.name + ' - ' + item?.dealerInfos?.address + ' - ' + item?.dealerInfos?.number + ' - ' + item?.dealerInfos?.city + '/' + item?.dealerInfos?.uf}`
    }

    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

    const handleNoteChange = (event: any) => localStorage.setItem("@FleetSolutions:note", event.target.value);

    const proposalDate = () => {
        const currentDate = new Date();

        const day = currentDate.getDate().toString().padStart(2, "0");
        const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
        const year = currentDate.getFullYear().toString();

        const hours = currentDate.getHours().toString().padStart(2, "0");
        const minutes = currentDate.getMinutes().toString().padStart(2, "0");

        const formattedDateTime = `${day}/${month}/${year} - ${hours}:${minutes}`;

        return formattedDateTime;
    };

    const handleGeneratePdf = () => {
        setShowPDFComponent(true);
        setTimeout(() => {
            if (hiddenContainerRef.current) {
                // Usa html2canvas para capturar a altura real do conteúdo HTML
                html2canvas(hiddenContainerRef.current, {
                    scale: 1,
                    useCORS: true // Permite o uso de imagens de outras origens
                }).then((canvas) => {
                    const contentWidth = canvas.width;
                    const contentHeight = canvas.height;
        
                    // Define as dimensões do PDF com base no conteúdo
                    const pdfWidth = contentWidth; 
                    const pdfHeight = contentHeight;
        
                    // Cria o PDF com o tamanho ajustado para evitar múltiplas páginas
                    const doc:any = new jsPDF({
                        orientation: 'portrait',
                        unit: 'pt',
                        format: [pdfWidth, pdfHeight], // Define o tamanho do PDF para caber todo o conteúdo
                    });
        
                    // Adiciona o conteúdo HTML ao PDF
                    doc.setFont("Open Sans", "normal");
                    doc.html(hiddenContainerRef.current as HTMLElement, {
                        x: 0,
                        y: 0,
                        html2canvas: {
                            scale: 1,
                            useCORS: true, // Permite cross-origin
                        },
                        callback: function (doc:any) {
                            // Ajuste final: removendo páginas em branco
                            const totalPages = doc?.internal?.getNumberOfPages();
                            if (totalPages > 1) {
                                doc.deletePage(totalPages); // Remove a última página em branco
                            }
        
                            // Salva o PDF com todo o conteúdo em uma única página
                            doc.save("Proposta_Comercial_Canal_Indireto.pdf");
                        },
                    });
                });
            }  
        }, 1000);

    };

    return (
        <div>
            <Container>
            {showPDFComponent && (
                  <div ref={hiddenContainerRef} style={{ position: "fixed", zIndex: "-9999999999", overflow: "hidden", top: 0 }}>
                    <ProposalPDF
                        parameters={{
                            productsList:allProductsData,
                            seller: fullname,
                            segmentSummary: resumeText,
                            proposalDate: proposalDate(),
                            segment: segment,
                            dealer:nameDealership
                        }}
                    />
                  </div>
                )}
                <h1 className="title">RESUMO DO PEDIDO</h1>
                <p className="description">Aqui você encontra todas as informações sobre o seu pedido</p>

                <div className="wrapper-print">
                    {/* <ReactToPrint
                        trigger={() => (
                            <button className="btn-print">
                                <div className="print">
                                    <AiTwotonePrinter color="#236FBC" size={25} /> Imprimir
                                </div>
                            </button>
                        )}
                        content={() => componentRef.current}
                    /> */}
                    <button onClick={() => handleGeneratePdf()} className="btn-print">
                        <div className="print">
                            <DownloadOutlined color="#236FBC" size={30} /> Exportar PDF da Proposta
                        </div>
                    </button>
                </div>

                <Content>
                    <WrapperLine>
                        {user && user.phone && (
                            <>
                                <div>
                                    <h2 style={{ textAlign: "left" }}>Dados da empresa</h2>
                                    <p className="title-description">CNPJ: </p>
                                    <p>{cnpjMask(user.cnpj)}</p>
                                    <p>&nbsp;</p>
                                    <p className="title-description">Razão Social: </p>
                                    <p>{user.companyName}</p>
                                </div>
                                <div></div>
                                <div>
                                    <h2 style={{ textAlign: "left" }}>Contatos da empresa</h2>
                                    <p className="title-description">Nome: </p>
                                    <p>{user.contactName}</p>
                                    <p>&nbsp;</p>
                                    <p className="title-description">CPF: </p>
                                    <p>{cpfMask(user.cpf)}</p>
                                </div>
                                <div></div>
                                <div>
                                    <h2 style={{ textAlign: "left" }}>&nbsp;</h2>
                                    <p className="title-description">Telefone: </p>
                                    <p>{formatPhone(user.phone)}</p>
                                    <p>&nbsp;</p>
                                    <p className="title-description">E-mail: </p>
                                    <p>{user.mail}</p>
                                </div>
                            </>
                        )}
                    </WrapperLine>
                    <hr />

                    {productsData.length &&
                        productsData.map((item: any, idx: number) => {
                            // Ajuste do índice global baseado na página atual
                            const globalIndex = idx + (currentPage - 1) * 5;

                            return (
                                <WrapperLine key={globalIndex}>
                                <ImgWithFallback className="image-car" src={`${defaultBackendUrl}/s3/veiculos/canal-indireto/${item.modelCode}.webp`} fallbacks={[`${defaultBackendUrl}/s3/veiculos/canal-indireto/${item.modelCode}.png`, `${noImageCar}`]} alt={item.model} />
                                <div style={{ width: "25%" }}>
                                    <p>Modelo: {item.name}</p>
                                    <p>Cor: {item.color}</p>
                                    {item.optional?.indexOf("acs=") === -1 && item.optional?.indexOf("opt=") === -1 && <p>Opcionais: {item.defaultOptional ?? item.optional}</p>}
                                    {getAccessories(item.optional) && <p>Acessórios: {getAccessories(item.optional)}</p>}
                                    {getOptionals(item.optional) && <p>Opcionais inclusos: {getOptionals(item.optional)}</p>}
                                </div>
                                <div style={{ width: "25%" }}>
                                    <p>Período: {item.deadline} meses</p>
                                    <p className="d-flex">
                                    Final de placa: 
                                    {allOrderItems[globalIndex]?.licensePlate?.length ? (
                                        <div className="license-choices d-flex ms-1">
                                        {allOrderItems[globalIndex].licensePlate.map((item:any, index:number) => (
                                            <div className="d-flex" key={item}>
                                                {item} {index !== allOrderItems[globalIndex]?.licensePlate?.length - 1 && <span className="me-1 ms-1"> / </span>}
                                            </div>
                                        ))}
                                        </div>
                                    ) : (
                                        <>Sem Escolha</>
                                    )}
                                    </p>
                                    <p>Km contratado: {item.monthlyKmValue}/mês</p>
                                    {allOrderItems[globalIndex]?.deliveryOption === 4 ? (
                                        <p>
                                            Local de retirada: {""}
                                            <div>
                                                <span className="address-head">DN do pedido</span>
                                                {nameDealership}
                                                {dealership?.street}, {dealership?.addressNumber}  - {dealership?.city}/{dealership?.uf}
                                            </div>
                                        </p>
                                    ) :
                                    allOrderItems[globalIndex]?.deliveryOption === 2 ? (
                                        <p>
                                            Local de retirada: {""}
                                            <div>
                                                <span className="address-head">Endereço do cadastro do cliente</span>
                                                {user?.address?.street}, {user?.address?.number} -{user?.address?.complement} - {user?.address?.district},{user?.address?.city}
                                            </div>
                                        </p>
                                    ) : 
                                    allOrderItems[globalIndex]?.deliveryOption === 1 ? (
                                        <p>
                                            Local de retirada: {""}
                                            <div>
                                                <span className="address-head">Concessionária/Parceiro</span>
                                                {getDealerAddress(allOrderItems[globalIndex])}
                                                {/* {user?.address?.street}, {user?.address?.number} -{user?.address?.complement} - {user?.address?.district},{user?.address?.city} */}
                                            </div>
                                        </p>
                                    ) : 
                                    allOrderItems[globalIndex]?.deliveryOption === 3 ? (
                                        <p>
                                            Local de retirada: {""}
                                            <div>
                                                <span className="address-head">Outro endereço</span>
                                            </div>
                                            {allOrderItems[globalIndex]?.address?.street}, {allOrderItems[globalIndex]?.address?.number} {allOrderItems[globalIndex]?.address?.complement} {allOrderItems[globalIndex]?.address?.district},{allOrderItems[globalIndex]?.address?.city}
                                        </p>
                                    ) : (
                                        <p>
                                            Local de retirada: {""}
                                            <div>
                                                <span className="address-head">Estado/Cidade indicados</span>
                                                {allOrderItems[globalIndex]?.uf} / {allOrderItems[globalIndex]?.cityforPickup}
                                            </div>
                                            {/* {item.defaultValueCity !== "Selecione" ? item.defaultValueCity + " - " + item.stateForPickup : nameDealership} */}
                                        </p>
                                    )}
                                </div>
                                <div style={{ width: "25%" }}>
                                    <p>Coparticipação em sinistro: 5% do preço público do veículo</p>
                                    <p>Km excedente: {formatValue(item.overrunKm)}</p>
                                    <p>Parcela mensal: {formatValue(item.monthlyInstallment)}</p>
                                </div>
                            </WrapperLine>
                            );
                        })}


                    <div className="content-to-print">
                        <p>${resumeText}</p>
                        <p>
                            <b>Avisos importantes</b>
                        </p>
                        <p>Pedido sujeito a análise de aprovação de crédito, bem como disponibilidade de estoque. A depender do caso, novas condições poderão ser apresentadas.</p>
                        <p>Serviços Contratados</p>
                        <ul>
                            <li>Documentação (IPVA + licenciamento + DPVAT + emplacamento);</li>
                            <li>Manutenções preventivas (de acordo com o manual do fabricante);</li>
                            <li>Assistência 24 horas;</li>
                            <li>Rastreador;</li>
                            <li>Gerenciamento de multas;</li>
                            <li>Veículo possui tapetes genuínos e película solar;</li>
                            <li>Proteção contra danos a terceiros e ao veículo.</li>
                        </ul>

                        <p>Proteções e Coberturas</p>

                        <p>É obrigatório a pernoite em garagem ou estacionamento fechado. No caso de PT roubo/furto do veículo, independentemente do motivo decorrente, deverá ser pago uma Coparticipação de 5% sobre o preço público do veículo no momento de assinatura do Contrato.</p>

                        <p>Coparticipação:</p>
                        <ul>
                            <li>Acidente de trânsito: até 5% do valor do veículo.</li>
                            <li>Furto, Roubo, Incêndio ou PT: de 5% do valor do veículo.</li>
                            <li>Proteção para terceiros: R$ 5.000,00.</li>
                            <li>Para-brisa e vidros: R$ 400,00</li>
                        </ul>

                        <p>Cobertura:</p>
                        <ul>
                            <li>Danos materiais a terceiros: R$ 100.000,00</li>
                            <li>Danos corporais a terceiros: R$ 100.000,00</li>
                            <li>Danos morais a terceiros: R$ 10.000,00</li>
                            <li>Remoção até 200km e cobertura de vidros</li>
                        </ul>

                        <p>Exclusões nas Manutenções </p>
                        <ul>
                            <li>Substituição de peças por desgaste decorrente de uso severo;</li>
                            <li>Substituição de componentes por mau uso; </li>
                            <li>Itens não cobertos por garantia; </li>
                            <li>Itens estéticos; </li>
                            <li>Lavagens, polimento, espelhamento e cristalização. </li>
                        </ul>

                        <hr />
                        <p>Esta é uma cotação indicativa sujeita a aprovação e análise de crédito. e rege-se por todas as cláusulas e condições gerais do mesmo.</p>
                        <p>
                            A LOCATÁRIA declara-se ciente e, desde já concorda que, eventual variação do preço dos veículos praticados pela fabricante apurados entre o pedido e respectiva entrega serão de sua responsabilidade. A LOCATÁRIA declara-se ciente e, desde já concorda que, é vedado o
                            cancelamento desse pedido, salvo expressa autorização do fabricante, sempre sendo de responsabilidade da LOCATÁRIA o pagamento dos custos e indenizações decorrentes de tal cancelamento.
                        </p>
                        <p>
                            As informações cadastrais prestadas são a expressão da verdade e, como tal, ficam V.Sªs. autorizadas a consultar e disponibilizá-las ao SERASA - Centralização dos Serviços dos Bancos S.A, SCI - Segurança ao Crédito e Informações e outras entidades congêneres, bem como às
                            empresas que fazem parte do Grupo Volkswagen ou prestadores de serviços devidamente aprovados por referidas empresas.
                        </p>
                        <p>
                            Por fim, para atendimento à Lei 12.414/11, que disciplina a formação e consulta a banco de dados com informações de adimplemento, de pessoas naturais ou de pessoais jurídicas, para formação de histórico de crédito, autorizo a abertura de cadastro em bancos de dados, bem
                            como o compartilhamento de informações de adimplemento.
                        </p>

                        <hr />
                        <p>Nome da Concessionária VW: {nameDealership}</p>
                        <p>Nome do Vendedor: {fullname}</p>
                        <p>Data/hora da cotação: {moment().format("DD/MM/YYYY - hh:mm")}</p>

                        <p>
                            Data de validade: {showExpirationDate()}/{moment().format("MM/YYYY - hh:mm")}{" "}
                        </p>

                        <p>Fleet Solutions, todos os direitos reservados.</p>
                        <p>https://fleetbrasil.com.br/</p>
                    </div>
                </Content>

                <Pagination page={currentPage} paginate={paginate} perPage={perPage} total={total} />

                <Content>
                    <h2>Resumo</h2>

                    <Summary>
                        <div className="longer-term">
                            <p>Maior prazo</p>
                            <p className="value-offer">{longerTerm} meses</p>
                        </div>
                        <div className="monthly">
                            <p>Parcela mensal</p>
                            <p className="value-offer">{formatValue(totalMonthlyInstallment)}</p>
                        </div>
                        <div className="credit">
                            <p>Valor total de contrato</p>
                            <p>{formatValue(requestCredit)}</p>
                        </div>
                       
                        <div dangerouslySetInnerHTML={{ __html: resumeText }}></div>
                    </Summary>
                </Content>

                <Content>
                    <h2>Observações</h2>

                    <Notes name="notes" maxLength={100} onChange={handleNoteChange} />
                </Content>

                <div className="buttons">
                    <Button onClick={() => handleContinueOrder("2")} className="btn-back">
                        Voltar
                    </Button>
                    <div className="wrapper-button">
                        <Button className="btn-save" style={{ display: "none" }}>
                            SALVAR PROPOSTA
                        </Button>
                        <Button className="btn-continue" onClick={() => handleContinueOrder("4")}>
                            CONTINUAR
                        </Button>
                    </div>
                </div>
            </Container>
            <Container className="printable" ref={componentRef}>
                {allProductsData.length &&
                    allProductsData.map((item: any, idx: number) => (
                        <div key={idx}>
                            <ImgWithFallback className="image-car" src={`${defaultBackendUrl}/s3/veiculos/canal-indireto/${item.modelCode}.webp`} fallbacks={[`${defaultBackendUrl}/s3/veiculos/canal-indireto/${item.modelCode}.png`, `${noImageCar}`]} alt={item.model} />

                            <div className="infos-group">
                                <div className="title">Informações do veículo:</div>
                                <div className="items">
                                    <div className="item">
                                        <span>Modelo:</span>
                                        {item.name}
                                    </div>
                                    <div className="item">
                                        <span>Cor:</span>
                                        {item.color}
                                    </div>
                                    <div className="item">
                                        <span>Ano:</span>
                                        {originalCartStorage?.find((cartItem: { modelCode: any; }) => cartItem.modelCode === item.modelCode)?.modelYear}
                                    </div>
                                    <div className="item">
                                        <span>Quantidade:</span>
                                        {originalCartStorage?.find((cartItem: { modelCode: any; }) => cartItem.modelCode === item.modelCode)?.quantity}
                                    </div>
                                </div>
                            </div>

                            <div className="infos-group">
                                <div className="title">Informações do contrato:</div>
                                <div className="items">
                                    <div className="item">
                                        <span>Período:</span>
                                        {item.deadline} meses
                                    </div>
                                    <div className="item">
                                        <span>Franquia mensal:</span>
                                        {item.monthlyKmValue} Kms
                                    </div>
                                    <div className="item">
                                        <span>Total da parcela:</span>
                                        {formatValue(item?.monthlyInstallment)}
                                    </div>
                                    <div className="item">
                                        <span>Total do contrato:</span>
                                        {formatValue(item?.totalRequestCredit)}
                                    </div>
                                    <div className="item">
                                        <span>Valor do KM excedente:</span>
                                        {formatValue(item?.overrunKm)}
                                    </div>
                                    <div className="item">
                                        <span>Coparticipação em sinistro:</span>
                                        5% do preço público do veículo
                                    </div>
                                </div>
                            </div>

  
                        </div>
                    ))}

                <div className="infos-group resume">
                    <div className="title">Resumo do pedido</div>
                    <div className="items">
                        <div className="item" dangerouslySetInnerHTML={{ __html: resumeText }}></div>
                    </div>
                </div>

                <div className="infos-group footer-group">
                    <div className="items">
                        <div className="item">
                            <span>Nome da Concessionária:</span>
                            {nameDealership}
                        </div>
                        <div className="item">
                            <span>Nome do Vendedor:</span>
                            {fullname}
                        </div>
                        <div className="item">
                            <span>Data/hora da cotação:</span>
                            {moment().format("DD/MM/YYYY - hh:mm")}
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default OrderSummer;
